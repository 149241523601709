import request from '@/utils/request'

export function getSumStatistics(data){
    return request({
        url: '/alarm/statistics/getSumStatistics',
        method: 'post',
        data: data
    })
}

export function getTaskNumStatistics(data){
    return request({
        url: '/alarm/statistics/getTaskNumStatistics',
        method: 'post',
        data: data
    })
}

export function getTaskFlightTimeStatistics(data){
    return request({
        url: '/alarm/statistics/getTaskFlightTimeStatistics',
        method: 'post',
        data: data
    })
}

export function getTaskFlightDistanceStatistics(data){
    return request({
        url: '/alarm/statistics/getTaskFlightDistanceStatistics',
        method: 'post',
        data: data
    })
}

export function getStatisticsByDeviceType(){
    return request({
        url: '/alarm/statistics/getStatisticsByDeviceType',
        method: 'get'
    })
}

export function getAlarmStatistics(data){
    return request({
        url: '/alarm/statistics/getAlarmStatisticsByReportNo',
        method: 'post',
        data: data
    })
}
export function getFlowStatistics(data){
    return request({
        url: '/alarm/statistics/getFlowStatistics',
        method: 'post',
        data: data
    })
}

export function getAlarmStatisticsByTaskId(data){
    return request({
        url: '/alarm/statistics/getAlarmStatisticsByTaskId',
        method: 'post',
        data: data
    })
}
export function getFlightDataByTaskId(data){
    return request({
        url: '/alarm/statistics/getFlightDataByTaskId',
        method: 'post',
        data: data
    })
}
export function getFlightDataMuByTaskId(data){
    return request({
        url: '/alarm/statistics/getFlightDataMuByTaskId',
        method: 'post',
        data: data
    })
}
export function getSatcomMuByTaskId(data){
    return request({
        url: '/alarm/statistics/getSatcomMuByTaskId',
        method: 'post',
        data: data
    })
}


// 查询无人机列表
export function listDrone () {
    return request({
        url: '/device/getTaskDeviceList',
        method: 'get'
    })
}
