/* unplugin-vue-components disabled */import { useCssVars as _useCssVars } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-28bea13d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "hm-bg-text"
};
const _hoisted_2 = ["innerHTML"];
export default {
  __name: 'index',
  props: {
    /**
     * 文字
     */
    text: {
      type: String,
      default: "文字"
    },
    /**
     * 文字大小
     */
    fontSize: {
      type: String,
      default: "20px"
    },
    /**
     * 文字颜色
     * @type Color
     */
    color: {
      type: String,
      default: "rgba(0,0,0,1)"
    },
    /**
     * 对齐
     * @type Enum
     * @options ["left", "center", "right"]
     */
    textAlign: {
      type: String,
      default: "center"
    },
    /**
     * 内边距
     */
    padding: {
      type: String,
      default: "5"
    },
    /**
     * 启用html渲染
     */
    enableHtmlRender: {
      type: Boolean,
      default: false
    }
  },
  setup(__props, {
    emit
  }) {
    const props = __props;
    _useCssVars(_ctx => ({
      "1431272e": _unref(cPadding),
      "0f9fa496": _unref(cTextAlign),
      "59a71973": _unref(cFontSize),
      "5f1b8480": _unref(cColor)
    }));
    const cPadding = ref("5px");
    const cTextAlign = ref("left");
    const cFontSize = ref("18px");
    const cColor = ref("rgba(0,0,0,1)");
    const cEnableHtmlRender = ref(false);
    watch(() => props.padding, (newVal, oldVal) => {
      cPadding.value = getCssUnit(newVal);
    });
    watch(() => props.fontSize, (newVal, oldVal) => {
      cFontSize.value = getCssUnit(newVal);
    });
    watch(() => props.textAlign, (newVal, oldVal) => {
      cTextAlign.value = newVal;
    });
    watch(() => props.color, (newVal, oldVal) => {
      cColor.value = newVal;
    });
    watch(() => props.enableHtmlRender, (newVal, oldVal) => {
      cEnableHtmlRender.value = newVal;
    });
    function getCssUnit(value) {
      if (isNaN(Number(value))) {
        return value;
      }
      return `${value}px`;
    }
    function onClick() {
      emit("onClick");
    }
    onMounted(() => {
      cPadding.value = getCssUnit(props.padding);
      cFontSize.value = getCssUnit(props.fontSize);
      cTextAlign.value = props.textAlign;
      cColor.value = props.color;
      cEnableHtmlRender.value = props.enableHtmlRender;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [!_unref(cEnableHtmlRender) ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        onClick: onClick
      }, _toDisplayString(__props.text), 1)) : (_openBlock(), _createElementBlock("p", {
        key: 1,
        innerHTML: __props.text
      }, null, 8, _hoisted_2))]);
    };
  }
};