/* unplugin-vue-components disabled */import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import { constantRoutes } from "@/router";
import { isHttp } from '@/utils/validate';
import useAppStore from '@/store/modules/app';
import usePermissionStore from '@/store/modules/permission';

// 当前激活菜单的 index

export default {
  __name: 'index',
  setup(__props) {
    const currentIndex = ref(null);
    const appStore = useAppStore();
    const permissionStore = usePermissionStore();
    const route = useRoute();
    const router = useRouter();
    const sidebarRouters = computed(() => permissionStore.sidebarRouters);
    const topMenus = computed(() => {
      let topMenus = [];
      sidebarRouters.value.map(menu => {
        if (menu.hidden !== true) {
          // 兼容顶部栏一级菜单内部跳转
          if (menu.path === "/system") {
            topMenus.push(menu.children[0]);
          } else {
            topMenus.push(menu);
          }
        }
      });
      return topMenus;
    });
    const activeMenu = computed(() => {
      const path = route.path;
      let activePath = path;
      if (path !== undefined && (path.indexOf("system/departmentCard") >= 0 || path.indexOf("system/log") >= 0)) {
        const tmpPath = path.substring(1, path.length);
        activePath = "/" + tmpPath.substring(0, tmpPath.lastIndexOf("/"));
        appStore.toggleRightBarHide(true);
      } else if (!route.children) {
        activePath = path;
        appStore.toggleRightBarHide(false);
      }
      activeRoutes(activePath);
      return activePath;
    });
    function handleSelect(key, keyPath) {
      currentIndex.value = key;
      const route = sidebarRouters.value.find(item => item.path === key);
      if (isHttp(key)) {
        // http(s):// 路径新窗口打开
        window.open(key, "_blank");
      } else if (!route || !route.children) {
        // 没有子路由路径内部打开
        router.push({
          path: key
        });
        appStore.toggleRightBarHide(false);
      } else {
        // 显示右侧联动菜单
        let currentroute = activeRoutes(key);
        router.push({
          path: currentroute[0].path
        });
        appStore.toggleRightBarHide(true);
      }
    }
    function activeRoutes(key) {
      let routes = [];
      if (childrenMenus.value && childrenMenus.value.length > 0) {
        childrenMenus.value.map(item => {
          if (key == item.parentPath || key == "index" && "" == item.path) {
            routes.push(item);
          }
        });
      }
      if (routes.length > 0) {
        permissionStore.setRightSystemRouters(routes);
      }
      return routes;
    }

    // 设置子路由
    const childrenMenus = computed(() => {
      let childrenMenus = [];
      sidebarRouters.value.map(router => {
        for (let item in router.children) {
          if (router.children[item].parentPath === undefined) {
            if (router.path === "/") {
              router.children[item].path = "/" + router.children[item].path;
            } else {
              if (!isHttp(router.children[item].path)) {
                router.children[item].path = router.path + "/" + router.children[item].path;
              }
            }
            router.children[item].parentPath = router.path;
          }
          childrenMenus.push(router.children[item]);
        }
      });
      return constantRoutes.concat(childrenMenus);
    });
    return (_ctx, _cache) => {
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_menu = _resolveComponent("el-menu");
      return _openBlock(), _createBlock(_component_el_menu, {
        "default-active": _unref(activeMenu),
        mode: "vertical",
        onSelect: handleSelect
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(topMenus), (item, index) => {
          return _openBlock(), _createBlock(_component_el_menu_item, {
            index: item.path
          }, {
            default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(item.meta.title), 1)]),
            _: 2
          }, 1032, ["index"]);
        }), 256))]),
        _: 1
      }, 8, ["default-active"]);
    };
  }
};