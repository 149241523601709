import { createApp, h } from 'vue';

import Cookies from "js-cookie";
import { plugin } from 'echarts-for-vue';
import * as echarts from 'echarts';

import ElementPlus from "element-plus";
import Antd from "ant-design-vue";
import 'ant-design-vue/dist/reset.css';
import * as Icons from '@ant-design/icons-vue'
import locale from "element-plus/es/locale/lang/zh-cn"; // 中文语言
import 'dayjs/locale/zh-cn';
import "@/assets/styles/index.scss"; // global css
import App from "./App";
import store from "./store";
import router from "./router";
import directive from "./directive"; // directive
// 注册指令
import plugins from "./plugins"; // plugins
import {download} from "@/utils/request";
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
// svg图标
// import 'virtual:svg-icons-register'
import SvgIcon from "@/components/SvgIcon";
import elementIcons from "@/components/SvgIcon/svgicon";
import "./assets/icons"; // icon
import "./permission"; // permission control
import {useDict} from "@/utils/dict";
import {checkPermi} from "@/utils/permission";
import {
  addDateRange,
  handleTree,
  parseTime,
  resetForm,
  selectDictLabel,
  selectDictLabels,
  byteToH,
  getDeviceIcon,
  getNames,
  getTime
} from "@/utils/satpro";

// 分页组件
import Pagination from "@/components/Pagination";
// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar";
// 文件上传组件
import FileUpload from "@/components/FileUpload";
// 图片上传组件
import ImageUpload from "@/components/ImageUpload";
// 图片预览组件
import ImagePreview from "@/components/ImagePreview";
// 自定义树选择组件
import TreeSelect from "@/components/TreeSelect";
// 字典标签组件
import DictTag from "@/components/DictTag";
import VueCesium from 'vue-cesium'
import 'vue-cesium/dist/index.css'

const app = createApp(App);

// 全局方法挂载
app.config.globalProperties.useDict = useDict;
app.config.globalProperties.checkPermi = checkPermi;
app.config.globalProperties.download = download;
app.config.globalProperties.parseTime = parseTime;
app.config.globalProperties.resetForm = resetForm;
app.config.globalProperties.handleTree = handleTree;
app.config.globalProperties.addDateRange = addDateRange;
app.config.globalProperties.selectDictLabel = selectDictLabel;
app.config.globalProperties.selectDictLabels = selectDictLabels;
app.config.globalProperties.byteToH = byteToH;
app.config.globalProperties.getDeviceIcon = getDeviceIcon;
app.config.globalProperties.getNames = getNames;
app.config.globalProperties.getTime = getTime;

// 全局组件挂载
app.component("DictTag", DictTag);
app.component("Pagination", Pagination);
app.component("TreeSelect", TreeSelect);
app.component("FileUpload", FileUpload);
app.component("ImageUpload", ImageUpload);
app.component("ImagePreview", ImagePreview);
app.component("RightToolbar", RightToolbar);
//全局注册图标
const icons = Icons
for (const i in icons) {
  app.component(i, icons[i])
}

app.use(router);
app.use(store);
app.use(plugins);
app.use(elementIcons);
app.use(Antd);
app.use(plugin, { echarts, h });
app.use(VueCesium, {
  cesiumPath: './Cesium/Cesium.js',
  accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjMDRkMDZhZS1jMDEzLTRjYzctYWU4NS1jMDQxZWIwMzBkZmQiLCJpZCI6MTQ3Nzg4LCJpYXQiOjE2OTY2NjM0MzV9.eflxVgQnjVj0YHtiAcFRL_P6DSkfp3zHdW-fZ1ASurA'
});
app.component("svg-icon", SvgIcon);
app.config.compilerOptions.isCustomElement = (tag) => {
  // 在这里定义哪些标签是自定义元素
  // 返回 true 表示这是一个自定义元素，Vue 将不会处理它
  return tag === 'dot' || tag.startsWith('dot-');
};

directive(app);

// 使用element-plus 并且设置全局的大小
app.use(ElementPlus, {
  locale: locale,
  // 支持 large、default、small
  size: Cookies.get("size") || "default",
});

app.mount("#app");
