/* unplugin-vue-components disabled */import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a541e3fe"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "el-quarter-picker"
};
const _hoisted_2 = {
  class: "top-box"
};
const _hoisted_3 = {
  class: "content-box"
};
const _hoisted_4 = ["onClick"];
import { Calendar, DArrowRight, DArrowLeft, CircleClose } from '@element-plus/icons-vue';
export default {
  __name: 'index',
  props: {
    placeholder: {
      type: String,
      default: '选择季度'
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  emits: ['change', 'update:modelValue'],
  setup(__props, {
    expose: __expose,
    emit
  }) {
    const props = __props;

    // v-model 双向绑定
    const innerValue = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      }
    });
    const visible = ref(false);
    const currYear = ref('');
    const quartlist = ref([{
      label: '第1季度',
      id: 1,
      color: "var(--el-datepicker-text-color)"
    }, {
      label: '第2季度',
      id: 2,
      color: "var(--el-datepicker-text-color)"
    }, {
      label: '第3季度',
      id: 3,
      color: "var(--el-datepicker-text-color)"
    }, {
      label: '第4季度',
      id: 4,
      color: "var(--el-datepicker-text-color)"
    }]);
    const currQuart = reactive({});
    currQuart.value = quartlist.value[0];
    const getCurrYear = () => {
      let date = new Date();
      currYear.value = date.getFullYear();
    };
    getCurrYear();
    // 获取现在的年份季度
    function getCurrQuarter() {
      const now = new Date();
      const month = now.getMonth() + 1; // 月份从 0 开始计数，需要加 1
      let index = 0;
      if (month <= 3) {
        index = 0;
      } else if (month <= 6) {
        index = 1;
      } else if (month <= 9) {
        index = 2;
      } else {
        index = 3;
      }
      // 高亮当前季度
      quartlist.value[index].color = "var(--el-color-primary)";
    }
    getCurrQuarter();
    const startDate = ref(['-01-01', '-04-01', '-07-01', '-10-01']);
    const endDate = ref(['-03-31', '-06-30', '-09-30', '-12-31']);
    const inputRef = ref(null);
    const inputFocus = () => {
      inputRef.value?.focus();
    };
    __expose({
      inputFocus
    });
    const nextYear = () => {
      // 获取焦点防止丢失焦点隐藏
      inputFocus();
      let year = new Date(new Date().setFullYear(currYear.value + 1)).getFullYear();
      currYear.value = year;
      innerValue.value = currYear.value + '-' + currQuart.value.label;
      let emitDate = {
        startDate: currYear.value + startDate.value[currQuart.value.id - 1],
        endDate: currYear.value + endDate.value[currQuart.value.id - 1]
      };
      emit('change', emitDate);
    };
    const preYear = () => {
      inputFocus();
      let year = new Date(new Date().setFullYear(currYear.value - 1)).getFullYear();
      currYear.value = year;
      innerValue.value = currYear.value + '-' + currQuart.value.label;
      let emitDate = {
        startDate: currYear.value + startDate.value[currQuart.value.id - 1],
        endDate: currYear.value + endDate.value[currQuart.value.id - 1]
      };
      emit('change', emitDate);
    };
    const handleQuart = item => {
      quartlist.value.forEach(v => {
        v.color = "var(--el-datepicker-text-color)";
        if (v.id == item.id) {
          v.color = "var(--el-color-primary)";
        }
      });
      currQuart.value = item;
      let emitDate = {
        startDate: currYear.value + startDate.value[currQuart.value.id - 1],
        endDate: currYear.value + endDate.value[currQuart.value.id - 1]
      };
      emit('change', emitDate);
      innerValue.value = currYear.value + '-' + currQuart.value.label;
      nextTick(() => {
        inputRef.value?.blur();
        visible.value = false;
      });
    };
    const clearVisble = ref(false);
    const clear = () => {
      innerValue.value = '';
      let emitDate = {
        startDate: '',
        endDate: ''
      };
      emit('change', emitDate);
    };
    const onFocus = () => {
      visible.value = true;
    };
    const onBlur = () => {
      visible.value = false;
    };
    watch(() => innerValue.value, val => {
      // 内容不为空时显示清除
      nextTick(() => {
        clearVisble.value = !!val;
      });
    });
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_popover = _resolveComponent("el-popover");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_popover, {
        visible: _unref(visible),
        width: 322,
        placement: "bottom-start",
        trigger: "click"
      }, {
        reference: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _unref(innerValue),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(innerValue) ? innerValue.value = $event : null),
          onFocus: onFocus,
          ref_key: "inputRef",
          ref: inputRef,
          onBlur: onBlur,
          placeholder: __props.placeholder,
          "prefix-icon": _unref(Calendar),
          readonly: false
        }, {
          suffix: _withCtx(() => [_withDirectives(_createVNode(_component_el_icon, {
            onClick: clear
          }, {
            default: _withCtx(() => [_createVNode(_unref(CircleClose))]),
            _: 1
          }, 512), [[_vShow, _unref(clearVisble)]])]),
          _: 1
        }, 8, ["modelValue", "placeholder", "prefix-icon"])]),
        default: _withCtx(() => [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_icon, {
          onClick: preYear
        }, {
          default: _withCtx(() => [_createVNode(_unref(DArrowLeft))]),
          _: 1
        }), _createElementVNode("span", null, _toDisplayString(_unref(currYear)) + " 年", 1), _createVNode(_component_el_icon, {
          onClick: nextYear
        }, {
          default: _withCtx(() => [_createVNode(_unref(DArrowRight))]),
          _: 1
        })]), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(quartlist), i => {
          return _openBlock(), _createElementBlock("span", {
            style: _normalizeStyle({
              color: i.color
            }),
            onClick: $event => handleQuart(i),
            key: i.id
          }, _toDisplayString(i.label), 13, _hoisted_4);
        }), 128))])])]),
        _: 1
      }, 8, ["visible"])]);
    };
  }
};