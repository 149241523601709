/* unplugin-vue-components disabled */import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-204f8882"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "right_captions_area"
};
const _hoisted_2 = {
  key: 0,
  class: "right_captions_area_list"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  key: 1,
  style: {
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_5 = {
  key: 2,
  style: {
    "width": "100%",
    "height": "100%"
  }
};
import VideoCard from "@/components/Card/VideoCard/index.vue";
import Player from "@/components/jessibuca/index.vue";
import XiGuaPlayer from "@/components/XGPlayer/xgPlayer.vue";
import TextCard from "@/components/Card/TextCard/index.vue";
import screenfull from "screenfull";
export default {
  __name: 'control',
  props: {
    // 是否显示提示
    isTasking: {
      type: Boolean,
      default: true
    }
  },
  setup(__props) {
    const props = __props;
    const {
      proxy
    } = getCurrentInstance();
    const pageData = inject("pageData");
    const handleChildEventTimeBack = inject("handleChildEventTimeBack");
    const switchVideo = inject("switchVideo");
    const fullscreen = ref(false);
    const videoStartRecord = ref(false);
    const aspectRatioState = ref(false);
    function onControlVideoCardFullScreenClick() {
      if (proxy.$refs["controlVideoRef"] === undefined) {
        return;
      }
      setTimeout(() => {
        requestAnimationFrame(() => {
          const dom = document.getElementsByClassName("control_video_card")[0];
          if (fullscreen.value) {
            screenfull.exit();
            fullscreen.value = false;
            proxy.$refs["controlVideoRef"].setJessibucaStyle(false);
          } else {
            screenfull.request(dom);
            fullscreen.value = true;
            proxy.$refs["controlVideoRef"].setJessibucaStyle(true);
          }
        });
      }, 50);
    }
    function onControlVideoCardVideoClick() {
      if (proxy.$refs["controlVideoRef"] === undefined) {
        return;
      }
      if (videoStartRecord.value) {
        proxy.$refs["controlVideoRef"].videoStopRecordAndSave();
        videoStartRecord.value = false;
      } else {
        proxy.$refs["controlVideoRef"].videoStartRecord();
        videoStartRecord.value = true;
        proxy.$modal.msgWarning("开始录屏，再次点击结束录屏");
      }
    }
    function onControlVideoCardScreenCutClick() {
      if (proxy.$refs["controlVideoRef"] === undefined) {
        return;
      }
      proxy.$refs["controlVideoRef"].videoScreenShot();
    }
    function onControlVideoCardReduction() {
      if (proxy.$refs["controlVideoRef"] === undefined) {
        return;
      }
      aspectRatioState.value = !aspectRatioState.value;
      proxy.$refs["controlVideoRef"].setJessibucaStyle(aspectRatioState.value);
    }
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createBlock(VideoCard, {
        ref: "controlVideoCardRef",
        "show-subtitle": false,
        "controlel-size": "small",
        "show-switch": false,
        "show-full-screen": __props.isTasking,
        "show-video-camera": __props.isTasking,
        "show-picture-outlined": __props.isTasking,
        "show-aspect-ratio": __props.isTasking,
        "aspect-ratio-state": _unref(aspectRatioState),
        onFullScreenClick: onControlVideoCardFullScreenClick,
        onVideoClick: onControlVideoCardVideoClick,
        onScreenCutClick: onControlVideoCardScreenCutClick,
        onReduction: onControlVideoCardReduction,
        class: "control_video_card"
      }, {
        default: _withCtx(() => [_unref(pageData).recordVideo.controlUrlList.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = $event => _unref(pageData).recordListShow.three = !_unref(pageData).recordListShow.three),
          class: "row"
        }, [_createVNode(_component_el_button, {
          style: {
            "width": "10.1rem"
          },
          size: "small"
        }, {
          default: _withCtx(() => [_createTextVNode("录像列表")]),
          _: 1
        })]), _unref(pageData).recordListShow.three ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pageData).recordVideo.controlUrlList, (info, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index,
            onClick: $event => _unref(switchVideo)(index, 2),
            class: "row"
          }, [info.liveRecordStartTime === _unref(pageData).recordVideo.controlIndex ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            size: "small",
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(info.liveRecordStartTime) + "—" + _toDisplayString(info.liveRecordStopTime), 1)]),
            _: 2
          }, 1024)) : (_openBlock(), _createBlock(_component_el_button, {
            key: 1,
            size: "small",
            type: "info"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(info.liveRecordStartTime) + "—" + _toDisplayString(info.liveRecordStopTime), 1)]),
            _: 2
          }, 1024))], 8, _hoisted_3);
        }), 128))])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _unref(pageData).deviceVideo.controlUrl !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(Player, {
          ref: "controlVideoRef",
          url: _unref(pageData).deviceVideo.controlUrl,
          fluent: "",
          autoplay: ""
        }, null, 8, ["url"])])) : _createCommentVNode("", true), _unref(pageData).recordVideo.controlUrl !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(XiGuaPlayer, {
          ref: "controlVideoRef",
          onReportedTime: _unref(handleChildEventTimeBack),
          id: 'app2',
          "video-index": _unref(pageData).recordVideo.controlId,
          "video-url": _unref(pageData).recordVideo.controlUrl,
          "start-time": _unref(pageData).recordVideo.controlPlayTime
        }, null, 8, ["onReportedTime", "video-index", "video-url", "start-time"])])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["show-full-screen", "show-video-camera", "show-picture-outlined", "show-aspect-ratio", "aspect-ratio-state"]);
    };
  }
};