/* unplugin-vue-components disabled */import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-357d5e01"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "upload-file"
};
const _hoisted_2 = {
  key: 0,
  class: "el-upload__tip"
};
const _hoisted_3 = {
  style: {
    "color": "#f56c6c"
  }
};
const _hoisted_4 = {
  style: {
    "color": "#f56c6c"
  }
};
const _hoisted_5 = {
  class: "el-icon-document"
};
const _hoisted_6 = {
  class: "ele-upload-list__item-content-action"
};
import { getToken } from "@/utils/auth";
export default {
  __name: 'index',
  props: {
    modelValue: [String, Object, Array],
    // 数量限制
    limit: {
      type: Number,
      default: 5
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ["doc", "xls", "ppt", "txt", "pdf"]
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    }
  },
  setup(__props, {
    emit
  }) {
    const props = __props;
    const {
      proxy
    } = getCurrentInstance();
    const number = ref(0);
    const uploadList = ref([]);
    const baseUrl = process.env.VUE_APP_BASE_API;
    const uploadFileUrl = ref(process.env.VUE_APP_BASE_API + "/common/upload"); // 上传的图片服务器地址
    const headers = ref({
      Authorization: "Bearer " + getToken()
    });
    const fileList = ref([]);
    const showTip = computed(() => props.isShowTip && (props.fileType || props.fileSize));
    watch(() => props.modelValue, val => {
      if (val) {
        let temp = 1;
        // 首先将值转为数组
        const list = Array.isArray(val) ? val : props.modelValue.split(",");
        // 然后将数组转为对象数组
        fileList.value = list.map(item => {
          if (typeof item === "string") {
            item = {
              name: item,
              url: item
            };
          }
          item.uid = item.uid || new Date().getTime() + temp++;
          return item;
        });
      } else {
        fileList.value = [];
        return [];
      }
    }, {
      deep: true,
      immediate: true
    });

    // 上传前校检格式和大小
    function handleBeforeUpload(file) {
      // 校检文件类型
      if (props.fileType.length) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = props.fileType.some(type => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        if (!isTypeOk) {
          proxy.$modal.msgError(`文件格式不正确, 请上传${props.fileType.join("/")}格式文件!`);
          return false;
        }
      }
      // 校检文件大小
      if (props.fileSize) {
        const isLt = file.size / 1024 / 1024 < props.fileSize;
        if (!isLt) {
          proxy.$modal.msgError(`上传文件大小不能超过 ${props.fileSize} MB!`);
          return false;
        }
      }
      proxy.$modal.loading("正在上传文件，请稍候...");
      number.value++;
      return true;
    }

    // 文件个数超出
    function handleExceed() {
      proxy.$modal.msgError(`上传文件数量不能超过 ${props.limit} 个!`);
    }

    // 上传失败
    function handleUploadError(err) {
      proxy.$modal.msgError("上传文件失败");
    }

    // 上传成功回调
    function handleUploadSuccess(res, file) {
      uploadList.value.push({
        name: res.fileName,
        url: res.fileName
      });
      if (uploadList.value.length === number.value) {
        fileList.value = fileList.value.filter(f => f.url !== undefined).concat(uploadList.value);
        uploadList.value = [];
        number.value = 0;
        emit("update:modelValue", listToString(fileList.value));
        proxy.$modal.closeLoading();
      }
    }

    // 删除文件
    function handleDelete(index) {
      fileList.value.splice(index, 1);
      emit("update:modelValue", listToString(fileList.value));
    }

    // 获取文件名称
    function getFileName(name) {
      if (name.lastIndexOf("/") > -1) {
        return name.slice(name.lastIndexOf("/") + 1);
      } else {
        return "";
      }
    }

    // 对象转成指定字符串分隔
    function listToString(list, separator) {
      let strs = "";
      separator = separator || ",";
      for (let i in list) {
        if (undefined !== list[i].url) {
          strs += list[i].url + separator;
        }
      }
      return strs != "" ? strs.substr(0, strs.length - 1) : "";
    }
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_upload = _resolveComponent("el-upload");
      const _component_el_link = _resolveComponent("el-link");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_upload, {
        ref: "upload",
        action: _unref(uploadFileUrl),
        "before-upload": handleBeforeUpload,
        "file-list": _unref(fileList),
        headers: _unref(headers),
        limit: __props.limit,
        "on-error": handleUploadError,
        "on-exceed": handleExceed,
        "on-success": handleUploadSuccess,
        "show-file-list": false,
        class: "upload-file-uploader",
        multiple: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          type: "primary"
        }, {
          default: _withCtx(() => [_createTextVNode("选取文件")]),
          _: 1
        })]),
        _: 1
      }, 8, ["action", "file-list", "headers", "limit"]), _unref(showTip) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createTextVNode(" 请上传 "), __props.fileSize ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createTextVNode(" 大小不超过 "), _createElementVNode("b", _hoisted_3, _toDisplayString(__props.fileSize) + "MB", 1)], 64)) : _createCommentVNode("", true), __props.fileType ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createTextVNode(" 格式为 "), _createElementVNode("b", _hoisted_4, _toDisplayString(__props.fileType.join("/")), 1)], 64)) : _createCommentVNode("", true), _createTextVNode(" 的文件 ")])) : _createCommentVNode("", true), _createVNode(_TransitionGroup, {
        class: "upload-file-list el-upload-list el-upload-list--text",
        name: "el-fade-in-linear",
        tag: "ul"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fileList), (file, index) => {
          return _openBlock(), _createElementBlock("li", {
            key: file.uid,
            class: "el-upload-list__item ele-upload-list__item-content"
          }, [_createVNode(_component_el_link, {
            href: `${_unref(baseUrl)}${file.url}`,
            underline: false,
            target: "_blank"
          }, {
            default: _withCtx(() => [_createElementVNode("span", _hoisted_5, _toDisplayString(getFileName(file.name)), 1)]),
            _: 2
          }, 1032, ["href"]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_link, {
            underline: false,
            type: "danger",
            onClick: $event => handleDelete(index)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])])]);
        }), 128))]),
        _: 1
      })]);
    };
  }
};