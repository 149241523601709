/* unplugin-vue-components disabled */import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, isRef as _isRef, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  style: {
    "float": "left"
  }
};
const _hoisted_2 = {
  style: {
    "float": "right",
    "color": "#8492a6",
    "font-size": "1.3rem"
  }
};
const _hoisted_3 = {
  style: {
    "text-align": "center",
    "margin-left": "-10rem",
    "margin-top": "1rem"
  }
};
import { getGenTable, updateGenTable } from "@/api/tool/gen";
import { optionselect as getDictOptionselect } from "@/api/system/dict/type";
import basicInfoForm from "./basicInfoForm";
import genInfoForm from "./genInfoForm";
export default {
  __name: 'editTable',
  setup(__props) {
    const route = useRoute();
    const {
      proxy
    } = getCurrentInstance();
    const activeName = ref("columnInfo");
    const tableHeight = ref(document.documentElement.scrollHeight - 245 + "px");
    const tables = ref([]);
    const columns = ref([]);
    const dictOptions = ref([]);
    const info = ref({});

    /** 提交按钮 */
    function submitForm() {
      const basicForm = proxy.$refs.basicInfo.$refs.basicInfoForm;
      const genForm = proxy.$refs.genInfo.$refs.genInfoForm;
      Promise.all([basicForm, genForm].map(getFormPromise)).then(res => {
        const validateResult = res.every(item => !!item);
        if (validateResult) {
          const genTable = Object.assign({}, info.value);
          genTable.columns = columns.value;
          genTable.params = {
            treeCode: info.value.treeCode,
            treeName: info.value.treeName,
            treeParentCode: info.value.treeParentCode,
            parentMenuId: info.value.parentMenuId
          };
          updateGenTable(genTable).then(res => {
            proxy.$modal.msgSuccess(res.msg);
            if (res.code === 200) {
              close();
            }
          });
        } else {
          proxy.$modal.msgError("表单校验未通过，请重新检查提交内容");
        }
      });
    }
    function getFormPromise(form) {
      return new Promise(resolve => {
        form.validate(res => {
          resolve(res);
        });
      });
    }
    function close() {
      const obj = {
        path: "/tool/gen",
        query: {
          t: Date.now(),
          pageNum: route.query.pageNum
        }
      };
      proxy.$router.push(obj);
    }
    (() => {
      const tableId = route.params && route.params.tableId;
      if (tableId) {
        // 获取表详细信息
        getGenTable(tableId).then(res => {
          columns.value = res.data.rows;
          info.value = res.data.info;
          tables.value = res.data.tables;
        });
        /** 查询字典下拉列表 */
        getDictOptionselect().then(response => {
          dictOptions.value = response.data;
        });
      }
    })();
    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_tabs = _resolveComponent("el-tabs");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_card = _resolveComponent("el-card");
      return _openBlock(), _createBlock(_component_el_card, null, {
        default: _withCtx(() => [_createVNode(_component_el_tabs, {
          modelValue: _unref(activeName),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(activeName) ? activeName.value = $event : null)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
            label: "基本信息",
            name: "basic"
          }, {
            default: _withCtx(() => [_createVNode(_unref(basicInfoForm), {
              ref: "basicInfo",
              info: _unref(info)
            }, null, 8, ["info"])]),
            _: 1
          }), _createVNode(_component_el_tab_pane, {
            label: "字段信息",
            name: "columnInfo"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_table, {
              ref: "dragTable",
              data: _unref(columns),
              "row-key": "columnId",
              "max-height": _unref(tableHeight)
            }, {
              default: _withCtx(() => [_createVNode(_component_el_table_column, {
                label: "序号",
                type: "index",
                "min-width": "5%"
              }), _createVNode(_component_el_table_column, {
                label: "字段列名",
                prop: "columnName",
                "min-width": "10%",
                "show-overflow-tooltip": true
              }), _createVNode(_component_el_table_column, {
                label: "字段描述",
                "min-width": "10%"
              }, {
                default: _withCtx(scope => [_createVNode(_component_el_input, {
                  modelValue: scope.row.columnComment,
                  "onUpdate:modelValue": $event => scope.row.columnComment = $event
                }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
                _: 1
              }), _createVNode(_component_el_table_column, {
                label: "物理类型",
                prop: "columnType",
                "min-width": "10%",
                "show-overflow-tooltip": true
              }), _createVNode(_component_el_table_column, {
                label: "Java类型",
                "min-width": "11%"
              }, {
                default: _withCtx(scope => [_createVNode(_component_el_select, {
                  modelValue: scope.row.javaType,
                  "onUpdate:modelValue": $event => scope.row.javaType = $event
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_option, {
                    label: "Long",
                    value: "Long"
                  }), _createVNode(_component_el_option, {
                    label: "String",
                    value: "String"
                  }), _createVNode(_component_el_option, {
                    label: "Integer",
                    value: "Integer"
                  }), _createVNode(_component_el_option, {
                    label: "Double",
                    value: "Double"
                  }), _createVNode(_component_el_option, {
                    label: "BigDecimal",
                    value: "BigDecimal"
                  }), _createVNode(_component_el_option, {
                    label: "Date",
                    value: "Date"
                  }), _createVNode(_component_el_option, {
                    label: "Boolean",
                    value: "Boolean"
                  })]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"])]),
                _: 1
              }), _createVNode(_component_el_table_column, {
                label: "java属性",
                "min-width": "10%"
              }, {
                default: _withCtx(scope => [_createVNode(_component_el_input, {
                  modelValue: scope.row.javaField,
                  "onUpdate:modelValue": $event => scope.row.javaField = $event
                }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
                _: 1
              }), _createVNode(_component_el_table_column, {
                label: "插入",
                "min-width": "5%"
              }, {
                default: _withCtx(scope => [_createVNode(_component_el_checkbox, {
                  "true-label": "1",
                  modelValue: scope.row.isInsert,
                  "onUpdate:modelValue": $event => scope.row.isInsert = $event
                }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
                _: 1
              }), _createVNode(_component_el_table_column, {
                label: "编辑",
                "min-width": "5%"
              }, {
                default: _withCtx(scope => [_createVNode(_component_el_checkbox, {
                  "true-label": "1",
                  modelValue: scope.row.isEdit,
                  "onUpdate:modelValue": $event => scope.row.isEdit = $event
                }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
                _: 1
              }), _createVNode(_component_el_table_column, {
                label: "列表",
                "min-width": "5%"
              }, {
                default: _withCtx(scope => [_createVNode(_component_el_checkbox, {
                  "true-label": "1",
                  modelValue: scope.row.isList,
                  "onUpdate:modelValue": $event => scope.row.isList = $event
                }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
                _: 1
              }), _createVNode(_component_el_table_column, {
                label: "查询",
                "min-width": "5%"
              }, {
                default: _withCtx(scope => [_createVNode(_component_el_checkbox, {
                  "true-label": "1",
                  modelValue: scope.row.isQuery,
                  "onUpdate:modelValue": $event => scope.row.isQuery = $event
                }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
                _: 1
              }), _createVNode(_component_el_table_column, {
                label: "查询方式",
                "min-width": "10%"
              }, {
                default: _withCtx(scope => [_createVNode(_component_el_select, {
                  modelValue: scope.row.queryType,
                  "onUpdate:modelValue": $event => scope.row.queryType = $event
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_option, {
                    label: "=",
                    value: "EQ"
                  }), _createVNode(_component_el_option, {
                    label: "!=",
                    value: "NE"
                  }), _createVNode(_component_el_option, {
                    label: ">",
                    value: "GT"
                  }), _createVNode(_component_el_option, {
                    label: ">=",
                    value: "GTE"
                  }), _createVNode(_component_el_option, {
                    label: "<",
                    value: "LT"
                  }), _createVNode(_component_el_option, {
                    label: "<=",
                    value: "LTE"
                  }), _createVNode(_component_el_option, {
                    label: "LIKE",
                    value: "LIKE"
                  }), _createVNode(_component_el_option, {
                    label: "BETWEEN",
                    value: "BETWEEN"
                  })]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"])]),
                _: 1
              }), _createVNode(_component_el_table_column, {
                label: "必填",
                "min-width": "5%"
              }, {
                default: _withCtx(scope => [_createVNode(_component_el_checkbox, {
                  "true-label": "1",
                  modelValue: scope.row.isRequired,
                  "onUpdate:modelValue": $event => scope.row.isRequired = $event
                }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
                _: 1
              }), _createVNode(_component_el_table_column, {
                label: "显示类型",
                "min-width": "12%"
              }, {
                default: _withCtx(scope => [_createVNode(_component_el_select, {
                  modelValue: scope.row.htmlType,
                  "onUpdate:modelValue": $event => scope.row.htmlType = $event
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_option, {
                    label: "文本框",
                    value: "input"
                  }), _createVNode(_component_el_option, {
                    label: "文本域",
                    value: "textarea"
                  }), _createVNode(_component_el_option, {
                    label: "下拉框",
                    value: "select"
                  }), _createVNode(_component_el_option, {
                    label: "单选框",
                    value: "radio"
                  }), _createVNode(_component_el_option, {
                    label: "复选框",
                    value: "checkbox"
                  }), _createVNode(_component_el_option, {
                    label: "日期控件",
                    value: "datetime"
                  }), _createVNode(_component_el_option, {
                    label: "图片上传",
                    value: "imageUpload"
                  }), _createVNode(_component_el_option, {
                    label: "文件上传",
                    value: "fileUpload"
                  }), _createVNode(_component_el_option, {
                    label: "富文本控件",
                    value: "editor"
                  })]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"])]),
                _: 1
              }), _createVNode(_component_el_table_column, {
                label: "字典类型",
                "min-width": "12%"
              }, {
                default: _withCtx(scope => [_createVNode(_component_el_select, {
                  modelValue: scope.row.dictType,
                  "onUpdate:modelValue": $event => scope.row.dictType = $event,
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择"
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dictOptions), dict => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      key: dict.dictType,
                      label: dict.dictName,
                      value: dict.dictType
                    }, {
                      default: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString(dict.dictName), 1), _createElementVNode("span", _hoisted_2, _toDisplayString(dict.dictType), 1)]),
                      _: 2
                    }, 1032, ["label", "value"]);
                  }), 128))]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"])]),
                _: 1
              })]),
              _: 1
            }, 8, ["data", "max-height"])]),
            _: 1
          }), _createVNode(_component_el_tab_pane, {
            label: "生成信息",
            name: "genInfo"
          }, {
            default: _withCtx(() => [_createVNode(_unref(genInfoForm), {
              ref: "genInfo",
              info: _unref(info),
              tables: _unref(tables)
            }, null, 8, ["info", "tables"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"]), _createVNode(_component_el_form, {
          "label-width": "10rem"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[1] || (_cache[1] = $event => submitForm())
          }, {
            default: _withCtx(() => [_createTextVNode("提交")]),
            _: 1
          }), _createVNode(_component_el_button, {
            onClick: _cache[2] || (_cache[2] = $event => close())
          }, {
            default: _withCtx(() => [_createTextVNode("返回")]),
            _: 1
          })])]),
          _: 1
        })]),
        _: 1
      });
    };
  }
};