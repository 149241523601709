/* unplugin-vue-components disabled */import __unplugin_components_0 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/SvgIcon/index.vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b4efb34e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "font-size": "1.6rem",
    "color": "#FFFFFF"
  }
};
import { constantRoutes } from "@/router";
import { isHttp } from '@/utils/validate';
import useAppStore from '@/store/modules/app';
import useSettingsStore from '@/store/modules/settings';
import usePermissionStore from '@/store/modules/permission';

// 顶部栏初始数

export default {
  __name: 'index',
  setup(__props) {
    const visibleNumber = ref(4);
    // 当前激活菜单的 index
    const currentIndex = ref(null);
    // 隐藏侧边栏路由
    const hideList = ['/index', '/user/profile'];
    const appStore = useAppStore();
    const settingsStore = useSettingsStore();
    const permissionStore = usePermissionStore();
    const route = useRoute();
    const router = useRouter();

    // 主题颜色
    const theme = computed(() => settingsStore.theme);
    // 所有的路由信息
    const routers = computed(() => permissionStore.defaultRoutes);

    // 顶部显示菜单
    const topMenus = computed(() => {
      let topMenus = [];
      routers.value.map(menu => {
        if (menu.hidden !== true) {
          // 兼容顶部栏一级菜单内部跳转
          if (menu.path === "/") {
            topMenus.push(menu.children[0]);
          } else {
            topMenus.push(menu);
          }
        }
      });
      return topMenus;
    });

    // 设置子路由
    const childrenMenus = computed(() => {
      let childrenMenus = [];
      routers.value.map(router => {
        for (let item in router.children) {
          if (router.children[item].parentPath === undefined) {
            if (router.path === "/") {
              router.children[item].path = "/" + router.children[item].path;
            } else {
              if (!isHttp(router.children[item].path)) {
                router.children[item].path = router.path + "/" + router.children[item].path;
              }
            }
            router.children[item].parentPath = router.path;
          }
          childrenMenus.push(router.children[item]);
        }
      });
      return constantRoutes.concat(childrenMenus);
    });

    // 默认激活的菜单
    const activeMenu = computed(() => {
      const path = route.path;
      let activePath = path;
      if (path !== undefined && path.lastIndexOf("/") > 0 && hideList.indexOf(path) === -1) {
        const tmpPath = path.substring(1, path.length);
        activePath = "/" + tmpPath.substring(0, tmpPath.indexOf("/"));
        appStore.toggleSideBarHide(true);
      } else if (!route.children) {
        activePath = path;
        appStore.toggleSideBarHide(false);
      }
      appStore.toggleRightBarHide(false);
      activeRoutes(activePath);
      return activePath;
    });
    function setVisibleNumber() {
      const width = document.body.getBoundingClientRect().width / 2;
      visibleNumber.value = parseInt(width / 100);
    }
    function handleSelect(key, keyPath) {
      currentIndex.value = key;
      const route = routers.value.find(item => item.path === key);
      if (isHttp(key)) {
        // http(s):// 路径新窗口打开
        window.open(key, "_blank");
      } else if (!route || !route.children) {
        // 没有子路由路径内部打开
        router.push({
          path: key
        });
        appStore.toggleSideBarHide(false);
      } else {
        // 显示左侧联动菜单
        let currentroute = activeRoutes(key);
        router.push({
          path: currentroute[0].path
        });
        appStore.toggleSideBarHide(true);
      }
    }
    function activeRoutes(key) {
      let routes = [];
      if (childrenMenus.value && childrenMenus.value.length > 0) {
        childrenMenus.value.map(item => {
          if (key == item.parentPath || key == "index" && "" == item.path) {
            routes.push(item);
          }
        });
      }
      if (routes.length > 0) {
        permissionStore.setSidebarRouters(routes);
      }
      return routes;
    }
    onMounted(() => {
      window.addEventListener('resize', setVisibleNumber);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', setVisibleNumber);
    });
    onMounted(() => {
      setVisibleNumber();
    });
    return (_ctx, _cache) => {
      const _component_svg_icon = __unplugin_components_0;
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_menu = _resolveComponent("el-menu");
      return _openBlock(), _createBlock(_component_el_menu, {
        "default-active": _unref(activeMenu),
        mode: "horizontal",
        onSelect: handleSelect
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(topMenus), (item, index) => {
          return _openBlock(), _createBlock(_component_el_menu_item, {
            index: item.path,
            style: {
              "display": "block"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_svg_icon, {
              "icon-class": item.meta.icon,
              style: _normalizeStyle('height:' + _unref(visibleNumber) + 'rem;width:' + _unref(visibleNumber) + 'rem;')
            }, null, 8, ["icon-class", "style"]), _createElementVNode("div", _hoisted_1, _toDisplayString(item.meta.title), 1)]),
            _: 2
          }, 1032, ["index"]);
        }), 256))]),
        _: 1
      }, 8, ["default-active"]);
    };
  }
};