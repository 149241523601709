import request from '@/utils/request'

// 查询告警图片
export function getAlarmList(query){
    return request({
        url: '/alarm/real/alarmImageList',
        method:'get',
        params: query
    })
}


// 查询告警图片详情
export function alarmImageInfo (imgId) {
    return request({
        url: '/alarm/real/alarmImageInfo/'+imgId,
        method: 'get'
    })
}
export function userTypes () {
    return request({
        url: '/alarm//real/userTypes',
        method: 'get'
    })
}
export function alarmTabelById (imgId) {
    return request({
        url: '/alarm/real/getAlarmInfoByImageId/'+imgId,
        method: 'get'
    })
}
// 查询首页摄像头信息以及告警信息
export function getHomePageAlarm () {
    return request({
        url: '/alarm/real/getHomePageAlarmData',
        method: 'get',
    })
}
// 查询所有识别点
export function getPointList () {
    return request({
        url: '/alarm/point/getPointListOptions',
        method: 'get'
    })
}

//
export function getRealNumbs (cameraId) {
    return request({
        url: '/alarm/real/getRealNumbs/'+ cameraId,
        method: 'get'
    })
}

// 根据所选的识别点Id查询识别点下面的 告警目标
export function clearAlarm (query) {
    return request({
        url: '/alarm/clear',
        method: 'post',
        data: query
    })
}