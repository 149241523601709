/* unplugin-vue-components disabled */import __unplugin_components_0 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/iFrame/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'index',
  setup(__props) {
    const url = ref(process.env.VUE_APP_BASE_API + "/swagger-ui/index.html");
    return (_ctx, _cache) => {
      const _component_i_frame = __unplugin_components_0;
      return _openBlock(), _createBlock(_component_i_frame, {
        src: _unref(url),
        "onUpdate:src": _cache[0] || (_cache[0] = $event => _isRef(url) ? url.value = $event : null)
      }, null, 8, ["src"]);
    };
  }
};