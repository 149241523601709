/* unplugin-vue-components disabled */import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-19292f4a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "component-upload-image"
};
const _hoisted_2 = {
  key: 0,
  class: "el-upload__tip"
};
const _hoisted_3 = {
  style: {
    "color": "#f56c6c"
  }
};
const _hoisted_4 = {
  style: {
    "color": "#f56c6c"
  }
};
const _hoisted_5 = ["src"];
import { getToken } from "@/utils/auth";
export default {
  __name: 'index',
  props: {
    modelValue: [String, Object, Array],
    // 图片数量限制
    limit: {
      type: Number,
      default: 5
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ["png", "jpg", "jpeg"]
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    }
  },
  setup(__props, {
    emit
  }) {
    const props = __props;
    const {
      proxy
    } = getCurrentInstance();
    const number = ref(0);
    const uploadList = ref([]);
    const dialogImageUrl = ref("");
    const dialogVisible = ref(false);
    const baseUrl = process.env.VUE_APP_BASE_API;
    const uploadImgUrl = ref(process.env.VUE_APP_BASE_API + "/common/upload"); // 上传的图片服务器地址
    const headers = ref({
      Authorization: "Bearer " + getToken()
    });
    const fileList = ref([]);
    const showTip = computed(() => props.isShowTip && (props.fileType || props.fileSize));
    watch(() => props.modelValue, val => {
      if (val) {
        // 首先将值转为数组
        const list = Array.isArray(val) ? val : props.modelValue.split(",");
        // 然后将数组转为对象数组
        fileList.value = list.map(item => {
          if (typeof item === "string") {
            if (item.indexOf(baseUrl) === -1) {
              item = {
                name: baseUrl + item,
                url: baseUrl + item
              };
            } else {
              item = {
                name: item,
                url: item
              };
            }
          }
          return item;
        });
      } else {
        fileList.value = [];
        return [];
      }
    }, {
      deep: true,
      immediate: true
    });

    // 删除图片
    function handleRemove(file, files) {
      emit("update:modelValue", listToString(fileList.value));
    }

    // 上传成功回调
    function handleUploadSuccess(res) {
      uploadList.value.push({
        name: res.fileName,
        url: res.fileName
      });
      if (uploadList.value.length === number.value) {
        fileList.value = fileList.value.filter(f => f.url !== undefined).concat(uploadList.value);
        uploadList.value = [];
        number.value = 0;
        emit("update:modelValue", listToString(fileList.value));
        proxy.$modal.closeLoading();
      }
    }

    // 上传前loading加载
    function handleBeforeUpload(file) {
      let isImg = false;
      if (props.fileType.length) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        isImg = props.fileType.some(type => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
      } else {
        isImg = file.type.indexOf("image") > -1;
      }
      if (!isImg) {
        proxy.$modal.msgError(`文件格式不正确, 请上传${props.fileType.join("/")}图片格式文件!`);
        return false;
      }
      if (props.fileSize) {
        const isLt = file.size / 1024 / 1024 < props.fileSize;
        if (!isLt) {
          proxy.$modal.msgError(`上传头像图片大小不能超过 ${props.fileSize} MB!`);
          return false;
        }
      }
      proxy.$modal.loading("正在上传图片，请稍候...");
      number.value++;
    }

    // 文件个数超出
    function handleExceed() {
      proxy.$modal.msgError(`上传文件数量不能超过 ${props.limit} 个!`);
    }

    // 上传失败
    function handleUploadError() {
      proxy.$modal.msgError("上传图片失败");
      proxy.$modal.closeLoading();
    }

    // 预览
    function handlePictureCardPreview(file) {
      dialogImageUrl.value = file.url;
      dialogVisible.value = true;
    }

    // 对象转成指定字符串分隔
    function listToString(list, separator) {
      let strs = "";
      separator = separator || ",";
      for (let i in list) {
        if (undefined !== list[i].url && list[i].url.indexOf("blob:") !== 0) {
          strs += list[i].url.replace(baseUrl, "") + separator;
        }
      }
      return strs != "" ? strs.substr(0, strs.length - 1) : "";
    }
    return (_ctx, _cache) => {
      const _component_plus = _resolveComponent("plus");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_upload = _resolveComponent("el-upload");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_upload, {
        action: _unref(uploadImgUrl),
        "before-upload": handleBeforeUpload,
        class: _normalizeClass({
          hide: _unref(fileList).length >= __props.limit
        }),
        "file-list": _unref(fileList),
        headers: _unref(headers),
        limit: __props.limit,
        "on-error": handleUploadError,
        "on-exceed": handleExceed,
        "on-preview": handlePictureCardPreview,
        "on-remove": handleRemove,
        "on-success": handleUploadSuccess,
        "show-file-list": true,
        "list-type": "picture-card",
        multiple: "",
        name: "file"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon, {
          class: "avatar-uploader-icon"
        }, {
          default: _withCtx(() => [_createVNode(_component_plus)]),
          _: 1
        })]),
        _: 1
      }, 8, ["action", "class", "file-list", "headers", "limit"]), _unref(showTip) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createTextVNode(" 请上传 "), __props.fileSize ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createTextVNode(" 大小不超过 "), _createElementVNode("b", _hoisted_3, _toDisplayString(__props.fileSize) + "MB", 1)], 64)) : _createCommentVNode("", true), __props.fileType ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createTextVNode(" 格式为 "), _createElementVNode("b", _hoisted_4, _toDisplayString(__props.fileType.join("/")), 1)], 64)) : _createCommentVNode("", true), _createTextVNode(" 的文件 ")])) : _createCommentVNode("", true), _createVNode(_component_el_dialog, {
        modelValue: _unref(dialogVisible),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(dialogVisible) ? dialogVisible.value = $event : null),
        "append-to-body": "",
        "close-on-click-modal": false,
        title: "预览",
        width: "80rem",
        draggable: ""
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: _unref(dialogImageUrl),
          style: {
            "display": "block",
            "max-width": "100%",
            "margin": "0 auto"
          }
        }, null, 8, _hoisted_5)]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};