/* unplugin-vue-components disabled */import __unplugin_components_2 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/Pagination/index.vue';
import __unplugin_components_1 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/DictTag/index.vue';
import __unplugin_components_0 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/RightToolbar/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, isRef as _isRef, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6c0fc0a7"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "height": "56rem"
  }
};
const _hoisted_2 = {
  class: "dialog-footer"
};
import { listPost, addPost, delPost, getPost, updatePost } from "@/api/system/post";
export default {
  __name: 'selectPost',
  setup(__props, {
    expose: __expose
  }) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      sys_normal_disable
    } = proxy.useDict("sys_normal_disable");
    const postList = ref([]);
    const visible = ref(false);
    const open = ref(false);
    const loading = ref(true);
    const showSearch = ref(true);
    const ids = ref([]);
    const single = ref(true);
    const multiple = ref(true);
    const total = ref(0);
    const title = ref("");
    const data = reactive({
      form: {},
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        postCode: undefined,
        postName: undefined,
        status: undefined
      },
      rules: {
        postName: [{
          required: true,
          message: "岗位名称不能为空",
          trigger: "blur"
        }],
        postCode: [{
          required: true,
          message: "岗位编码不能为空",
          trigger: "blur"
        }],
        postSort: [{
          required: true,
          message: "岗位顺序不能为空",
          trigger: "blur"
        }]
      }
    });
    const {
      queryParams,
      form,
      rules
    } = toRefs(data);

    // 显示弹框
    function show() {
      getList();
      visible.value = true;
    }

    /** 查询岗位列表 */
    function getList() {
      loading.value = true;
      listPost(queryParams.value).then(response => {
        postList.value = response.rows;
        total.value = response.total;
        loading.value = false;
      });
    }
    /** 取消按钮 */
    function cancel() {
      open.value = false;
      reset();
    }
    /** 表单重置 */
    function reset() {
      form.value = {
        postId: undefined,
        postCode: undefined,
        postName: undefined,
        postSort: 0,
        status: "0",
        remark: undefined
      };
      proxy.resetForm("postRef");
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      queryParams.value.pageNum = 1;
      getList();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      proxy.resetForm("queryRef");
      handleQuery();
    }
    /** 多选框选中数据 */
    function handleSelectionChange(selection) {
      ids.value = selection.map(item => item.postId);
      single.value = selection.length != 1;
      multiple.value = !selection.length;
    }
    /** 新增按钮操作 */
    function handleAdd() {
      reset();
      open.value = true;
      title.value = "添加岗位";
    }
    /** 修改按钮操作 */
    function handleUpdate(row) {
      reset();
      const postId = row.postId || ids.value;
      getPost(postId).then(response => {
        form.value = response.data;
        open.value = true;
        title.value = "修改岗位";
      });
    }
    /** 提交按钮 */
    function submitForm() {
      proxy.$refs["postRef"].validate(valid => {
        if (valid) {
          if (form.value.postId != undefined) {
            updatePost(form.value).then(response => {
              proxy.$modal.msgSuccess("修改成功");
              open.value = false;
              getList();
            });
          } else {
            addPost(form.value).then(response => {
              proxy.$modal.msgSuccess("新增成功");
              open.value = false;
              getList();
            });
          }
        }
      });
    }
    /** 删除按钮操作 */
    function handleDelete(row) {
      const postIds = row.postId || ids.value;
      proxy.$modal.confirm('是否确认删除岗位编号为"' + postIds + '"的数据项？').then(function () {
        return delPost(postIds);
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    }
    /** 导出按钮操作 */
    function handleExport() {
      proxy.download("system/post/export", {
        ...queryParams.value
      }, `post_${new Date().getTime()}.xlsx`);
    }
    __expose({
      show
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      const _component_right_toolbar = __unplugin_components_0;
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_dict_tag = __unplugin_components_1;
      const _component_el_tooltip = _resolveComponent("el-tooltip");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_2;
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _component_el_input_number = _resolveComponent("el-input-number");
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_dialog, {
        title: "岗位管理",
        modelValue: _unref(visible),
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _isRef(visible) ? visible.value = $event : null),
        width: "120rem",
        "close-on-click-modal": false,
        "append-to-body": "",
        draggable: ""
      }, {
        default: _withCtx(() => [_withDirectives(_createVNode(_component_el_form, {
          model: _unref(queryParams),
          ref: "queryRef",
          inline: true
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "岗位编码",
            prop: "postCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).postCode,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).postCode = $event),
              placeholder: "请输入岗位编码",
              clearable: "",
              onKeyup: _withKeys(handleQuery, ["enter"])
            }, null, 8, ["modelValue", "onKeyup"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "岗位名称",
            prop: "postName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).postName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).postName = $event),
              placeholder: "请输入岗位名称",
              clearable: "",
              onKeyup: _withKeys(handleQuery, ["enter"])
            }, null, 8, ["modelValue", "onKeyup"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "状态",
            prop: "status"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(queryParams).status,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(queryParams).status = $event),
              placeholder: "岗位状态",
              clearable: ""
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_normal_disable), dict => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: dict.value,
                  label: dict.label,
                  value: dict.value
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              class: "satpro-button",
              icon: "Search",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            }), _createVNode(_component_el_button, {
              class: "satpro-button",
              icon: "Refresh",
              onClick: resetQuery
            }, {
              default: _withCtx(() => [_createTextVNode("重置")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"]), [[_vShow, _unref(showSearch)]]), _createVNode(_component_el_row, {
          gutter: 10,
          class: "mb8"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 1.5
          }, {
            default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
              plain: "",
              icon: "Plus",
              class: "satpro-button",
              onClick: handleAdd
            }, {
              default: _withCtx(() => [_createTextVNode("新增")]),
              _: 1
            })), [[_directive_hasPermi, ['system:post:add']]])]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 1.5
          }, {
            default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
              plain: "",
              icon: "Edit",
              class: "satpro-button",
              disabled: _unref(single),
              onClick: handleUpdate
            }, {
              default: _withCtx(() => [_createTextVNode("修改")]),
              _: 1
            }, 8, ["disabled"])), [[_directive_hasPermi, ['system:post:edit']]])]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 1.5
          }, {
            default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
              class: "satpro-button",
              plain: "",
              icon: "Delete",
              disabled: _unref(multiple),
              onClick: handleDelete
            }, {
              default: _withCtx(() => [_createTextVNode("删除")]),
              _: 1
            }, 8, ["disabled"])), [[_directive_hasPermi, ['system:post:remove']]])]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 1.5
          }, {
            default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
              class: "satpro-button",
              plain: "",
              icon: "Download",
              onClick: handleExport
            }, {
              default: _withCtx(() => [_createTextVNode("导出")]),
              _: 1
            })), [[_directive_hasPermi, ['system:post:export']]])]),
            _: 1
          }), _createVNode(_component_right_toolbar, {
            showSearch: _unref(showSearch),
            "onUpdate:showSearch": _cache[3] || (_cache[3] = $event => _isRef(showSearch) ? showSearch.value = $event : null),
            onQueryTable: getList
          }, null, 8, ["showSearch"])]),
          _: 1
        }), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_table, {
          data: _unref(postList),
          onSelectionChange: handleSelectionChange,
          "header-cell-style": {
            background: 'transparent !important',
            color: '#FFFFFF'
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            type: "selection",
            width: "55",
            align: "center"
          }), _createVNode(_component_el_table_column, {
            label: "岗位编号",
            align: "center",
            prop: "postId"
          }), _createVNode(_component_el_table_column, {
            label: "岗位编码",
            align: "center",
            prop: "postCode"
          }), _createVNode(_component_el_table_column, {
            label: "岗位名称",
            align: "center",
            prop: "postName"
          }), _createVNode(_component_el_table_column, {
            label: "岗位排序",
            align: "center",
            prop: "postSort"
          }), _createVNode(_component_el_table_column, {
            label: "状态",
            align: "center",
            prop: "status"
          }, {
            default: _withCtx(scope => [_createVNode(_component_dict_tag, {
              options: _unref(sys_normal_disable),
              value: scope.row.status
            }, null, 8, ["options", "value"])]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "创建时间",
            align: "center",
            prop: "createTime",
            width: "180"
          }, {
            default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.parseTime(scope.row.createTime)), 1)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "操作",
            align: "center",
            "class-name": "small-padding fixed-width"
          }, {
            default: _withCtx(scope => [_createVNode(_component_el_tooltip, {
              content: "修改",
              placement: "top",
              "auto-close": 500
            }, {
              default: _withCtx(() => [_withDirectives(_createVNode(_component_el_button, {
                type: "primary",
                link: "",
                icon: "Edit",
                onClick: $event => handleUpdate(scope.row)
              }, null, 8, ["onClick"]), [[_directive_hasPermi, ['system:post:edit']]])]),
              _: 2
            }, 1024), _createVNode(_component_el_tooltip, {
              content: "删除",
              placement: "top",
              "auto-close": 500
            }, {
              default: _withCtx(() => [_withDirectives(_createVNode(_component_el_button, {
                type: "primary",
                link: "",
                icon: "Delete",
                onClick: $event => handleDelete(scope.row)
              }, null, 8, ["onClick"]), [[_directive_hasPermi, ['system:post:remove']]])]),
              _: 2
            }, 1024)]),
            _: 1
          })]),
          _: 1
        }, 8, ["data"]), _withDirectives(_createVNode(_component_pagination, {
          total: _unref(total),
          page: _unref(queryParams).pageNum,
          "onUpdate:page": _cache[4] || (_cache[4] = $event => _unref(queryParams).pageNum = $event),
          limit: _unref(queryParams).pageSize,
          "onUpdate:limit": _cache[5] || (_cache[5] = $event => _unref(queryParams).pageSize = $event),
          onPagination: getList
        }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]])])]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_dialog, {
        title: _unref(title),
        modelValue: _unref(open),
        "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _isRef(open) ? open.value = $event : null),
        width: "50rem",
        "close-on-click-modal": false,
        "append-to-body": "",
        draggable: ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: cancel
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref: "postRef",
          model: _unref(form),
          rules: _unref(rules),
          "label-width": "12rem"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "岗位名称",
            prop: "postName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).postName,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).postName = $event),
              placeholder: "请输入岗位名称"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "岗位编码",
            prop: "postCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).postCode,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(form).postCode = $event),
              placeholder: "请输入编码名称"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "岗位顺序",
            prop: "postSort"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input_number, {
              modelValue: _unref(form).postSort,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _unref(form).postSort = $event),
              "controls-position": "right",
              min: 0
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "岗位状态",
            prop: "status"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio_group, {
              modelValue: _unref(form).status,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _unref(form).status = $event)
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_normal_disable), dict => {
                return _openBlock(), _createBlock(_component_el_radio, {
                  key: dict.value,
                  label: dict.value
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(dict.label), 1)]),
                  _: 2
                }, 1032, ["label"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "备注",
            prop: "remark"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).remark,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _unref(form).remark = $event),
              type: "textarea",
              placeholder: "请输入内容"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["title", "modelValue"])], 64);
    };
  }
};