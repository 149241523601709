import axios from "axios";
import {ElMessage} from "element-plus";
import {saveAs} from "file-saver";
import {getToken} from "@/utils/auth";
import errorCode from "@/utils/errorCode";
import {blobValidate} from "@/utils/satpro";

const baseURL = process.env.VUE_APP_BASE_API;

export default {
  name(name, isDelete = true) {
    window.location.href = baseURL + "/common/download?fileName=" + encodeURI(name) + "&delete=" + isDelete;
    /*var url = baseURL + "/common/download?fileName=" + encodeURI(name) + "&delete=" + isDelete;
    axios({
      method: "get",
      url: url,
      responseType: "blob",
      headers: {"Authorization": "Bearer " + getToken()},
    }).then(async (res) => {
      const isLogin = await blobValidate(res.data);
      if (isLogin) {
        const blob = new Blob([res.data]);
        this.saveAs(blob, decodeURI(res.headers["download-filename"]));
      } else {
        this.printErrMsg(res.data);
      }
    });*/
  },
  resource(resource) {
    var url = baseURL + "/common/download/resource?resource=" + encodeURI(resource);
    axios({
      method: "get",
      url: url,
      responseType: "blob",
      headers: {"Authorization": "Bearer " + getToken()},
    }).then(async (res) => {
      const isLogin = await blobValidate(res.data);
      if (isLogin) {
        const blob = new Blob([res.data]);
        this.saveAs(blob, decodeURI(res.headers["download-filename"]));
      } else {
        this.printErrMsg(res.data);
      }
    });
  },
  zip(url, name) {
    var url = baseURL + url;
    axios({
      method: "get",
      url: url,
      responseType: "blob",
      headers: {"Authorization": "Bearer " + getToken()},
    }).then(async (res) => {
      const isLogin = await blobValidate(res.data);
      if (isLogin) {
        const blob = new Blob([res.data], {type: "application/zip"});
        this.saveAs(blob, name);
      } else {
        this.printErrMsg(res.data);
      }
    });
  },
  saveAs(text, name, opts) {
    saveAs(text, name, opts);
  },
  async printErrMsg(data) {
    const resText = await data.text();
    const rspObj = JSON.parse(resText);
    const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode["default"];
    ElMessage.error(errMsg);
  },
};

