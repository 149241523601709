import request from '@/utils/request'
import { parseStrEmpty } from "@/utils/satpro";

export function getDeviceList(query){
    return request({
        url: '/device/list',
        method:'get',
        params: query
    })
}

// 查询设备用户关联列表
export function getUserList () {
    return request({
        url: '/device/getUserList',
        method: 'get'
    })
}
export function getTreeselectUserList () {
    return request({
        url: '/device/getTreeselectUserList',
        method: 'get'
    })
}

export function addDevice (data) {
    return request({
        url: '/device',
        method: 'post',
        data: data
    })
}
export function updateDevice(data){
    return request({
        url: '/device',
        method:'put',
        data:data
    })
}

export function getInfo (id) {
    return request({
        url: '/device/deviceId/' + parseStrEmpty(id),
        method: 'get'
    })
}
export function delDevice(id){
    return request({
        url: '/device/'+id,
        method:'DELETE',
    })
}

export function deviceVideoPlay(query){
    return request({
        url: '/device/videoPlay',
        method:'get',
        params:query
    })
}

export function exportDevice (query) {
    return request({
        url: '/device/export',
        method: 'get',
        params: query
    })
}

export function importTemplate () {
    return request({
        url: '/device/importTemplate',
        method: 'get'
    })
}

// 查询关联设备
export function getDeviceflat (deviceId) {
    return request({
        url: '/device/deviceflat/' + deviceId,
        method: 'get'
    })
}// 查询关联设备
export function getDevicePushInfo (deviceName) {
    return request({
        url: '/device/getDevicePushInfo/' + deviceName,
        method: 'get'
    })
}

// 保存关联设备
export function updateDeviceflat (data) {
    return request({
        url: '/device/deviceflat',
        method: 'put',
        params: data
    })
}

export function getDeviceflatAll(query){
    return request({
        url: '/device/listByType',
        method:'get',
        params: query
    })
}

export function getVideoDeviceList(){
    return request({
        url: '/device/getVideoDeviceList',
        method:'get'
    })
}
