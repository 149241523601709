import request from "@/utils/request";

export function getTaskDevice(deviceId) {
    return request({
        url: 'droneTaskDevice/list?deviceId='+deviceId,
        method: 'get'
    })
}
export function getDeviceFlat(deviceId) {
    return request({
        url: 'device/deviceflat/'+deviceId,
        method: 'get'
    })
}

export function getFlightData() {
    return request({
        url: 'alarm/getFlightData',
        method: 'get'
    })
}
export function getSdpTerminalStatus(terminalId) {
    return request({
        url: 'sdp/getSdpTerminalStatus/'+terminalId,
        method: 'get'
    })
}
export function getSdpTerminalInfo(name) {
    return request({
        url: 'sdp/getSdpTerminalInfo/'+name,
        method: 'get'
    })
}

//获取历史任务
export function getTaskListByDeviceId(query) {
    return request({
        url: 'droneTaskDevice/getTaskListByDeviceId',
        method: 'get',
        params: query
    })
}
//获取设备最近的一次任务ID
export function getTaskInfo(query) {
    return request({
        url: 'droneTaskDevice/getTaskInfo',
        method: 'get',
        params: query
    })
}

//获取数据列详情字段信息
export function getDataTableColumn(key) {
    return request({
        url: '/dataArchiver/getDataTableColumn/'+ key,
        method: 'get'
    })
}
