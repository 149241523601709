/* unplugin-vue-components disabled */import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue";
import ElQuarterPicker from "@/components/ElQuarterPicker";
import { getDateRange, getSpecifyEarlyEndMonth, getSpecifyYear } from "@/utils/date";
export default {
  __name: 'index',
  emits: ['setDateRange'],
  setup(__props, {
    expose: __expose,
    emit
  }) {
    const {
      proxy
    } = getCurrentInstance();
    const dateRange = ref([]); // 日期范围
    const datesValue = ref("");
    const weekValue = ref("");
    const monthValue = ref("");
    const quarterValue = ref("");
    const yearValue = ref("");
    const groupByType = ref("day");
    const timeType = ref("daterange"); //默认选中天
    const timeTypeList = ref([{
      key: "daterange",
      label: "按日期"
    }, {
      key: "week",
      label: "按周"
    }, {
      key: "month",
      label: "按月份"
    }, {
      key: "quarter",
      label: "按季度"
    }, {
      key: "year",
      label: "按年"
    }]);
    function formatDate(data) {
      dateRange.value = [];
      if (timeType.value === "daterange") {
        if (datesValue.value !== null) {
          dateRange.value.push(new Date(datesValue.value[0] + " 00:00:00"));
          dateRange.value.push(new Date(datesValue.value[1] + " 23:59:59"));
        }
        groupByType.value = "day";
      } else if (timeType.value === "week") {
        if (weekValue.value !== null) {
          dateRange.value = getDateRange(weekValue.value);
        }
        groupByType.value = "week";
      } else if (timeType.value === "month") {
        if (monthValue.value !== null) {
          dateRange.value = getSpecifyEarlyEndMonth(monthValue.value);
        }
        groupByType.value = "month";
      } else if (timeType.value === "quarter") {
        if (data.startDate !== "" && data.endDate !== "") {
          dateRange.value.push(new Date(data.startDate + " 00:00:00"));
          dateRange.value.push(new Date(data.endDate + " 23:59:59"));
        }
        groupByType.value = "quarter";
      } else if (timeType.value === "year") {
        if (yearValue.value !== null) {
          dateRange.value = getSpecifyYear(yearValue.value);
        }
        groupByType.value = "year";
      }
      emit("setDateRange", dateRange.value);
      emit("setGroupByType", groupByType.value);
    }
    function selectCurrentType() {
      datesValue.value = "";
      weekValue.value = "";
      monthValue.value = "";
      quarterValue.value = "";
      yearValue.value = "";
    }
    function reset() {
      dateRange.value = [];
      datesValue.value = "";
      weekValue.value = "";
      monthValue.value = "";
      quarterValue.value = "";
      yearValue.value = "";
      timeType.value = "daterange";
    }
    function history(value) {
      timeType.value = "daterange";
      datesValue.value = value.map(item => {
        return proxy.parseTime(item, '{y}-{m}-{d}');
      });
    }
    __expose({
      reset,
      history
    });
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_select, {
        modelValue: _unref(timeType),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(timeType) ? timeType.value = $event : null),
        style: {
          "width": "8rem",
          "display": "inline-block"
        },
        onChange: _cache[1] || (_cache[1] = $event => selectCurrentType())
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(timeTypeList), item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.key,
            label: item.label,
            value: item.key
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"]), _unref(timeType) === 'daterange' ? (_openBlock(), _createBlock(_component_el_date_picker, {
        key: 0,
        modelValue: _unref(datesValue),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _isRef(datesValue) ? datesValue.value = $event : null),
        type: _unref(timeType),
        style: {
          "width": "24rem",
          "display": "inline-block"
        },
        onChange: formatDate,
        "end-placeholder": "结束日期",
        "range-separator": "-",
        "start-placeholder": "开始日期",
        "value-format": "YYYY-MM-DD"
      }, null, 8, ["modelValue", "type"])) : _createCommentVNode("", true), _unref(timeType) === 'week' ? (_openBlock(), _createBlock(_component_el_date_picker, {
        key: 1,
        modelValue: _unref(weekValue),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(weekValue) ? weekValue.value = $event : null),
        type: _unref(timeType),
        style: {
          "width": "24rem",
          "display": "inline-block"
        },
        format: "YYYY-第ww周",
        onChange: formatDate,
        placeholder: "请选择周"
      }, null, 8, ["modelValue", "type"])) : _createCommentVNode("", true), _unref(timeType) === 'month' ? (_openBlock(), _createBlock(_component_el_date_picker, {
        key: 2,
        modelValue: _unref(monthValue),
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _isRef(monthValue) ? monthValue.value = $event : null),
        type: _unref(timeType),
        style: {
          "width": "24rem",
          "display": "inline-block"
        },
        onChange: formatDate,
        placeholder: "请选择月份"
      }, null, 8, ["modelValue", "type"])) : _createCommentVNode("", true), _unref(timeType) === 'quarter' ? (_openBlock(), _createBlock(_unref(ElQuarterPicker), {
        key: 3,
        ref: "quarterRef",
        modelValue: _unref(quarterValue),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _isRef(quarterValue) ? quarterValue.value = $event : null),
        onChange: formatDate,
        placeholder: "请选择季度"
      }, null, 8, ["modelValue"])) : _createCommentVNode("", true), _unref(timeType) === 'year' ? (_openBlock(), _createBlock(_component_el_date_picker, {
        key: 4,
        modelValue: _unref(yearValue),
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _isRef(yearValue) ? yearValue.value = $event : null),
        type: _unref(timeType),
        style: {
          "width": "24rem",
          "display": "inline-block"
        },
        onChange: formatDate,
        placeholder: "请选择年"
      }, null, 8, ["modelValue", "type"])) : _createCommentVNode("", true)], 64);
    };
  }
};