import request from '@/utils/request'

// 查询上传文件列表
export function listInstructions (query) {
    return request({
        url: '/device/instructions/list',
        method: 'get',
        params: query
    })
}
// 新增上传文件
export function addInstructions (data) {
    return request({
        url: '/device/instructions/add',
        method: 'post',
        data: data
    })
}
// 删除上传文件
export function delInstructions (id) {
    return request({
        url: '/device/instructions/' + id,
        method: 'delete'
    })
}
