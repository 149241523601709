/* unplugin-vue-components disabled */import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id"];
import { onMounted, watch } from "vue";
import Player from 'xgplayer';
import HlsPlugin from "xgplayer-hls";
import "xgplayer/dist/index.min.css";
import TextTrack from 'xgplayer/es/plugins/track';
import "xgplayer/es/plugins/track/index.css";
export default {
  __name: 'xgPlayer',
  props: {
    id: {
      type: String,
      required: true
    },
    videoIndex: {
      type: String,
      required: true
    },
    videoUrl: {
      type: String,
      default: () => ""
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    //封面图
    poster: {
      type: String,
      default: () => ""
    },
    playsinline: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    //起播时间
    startTime: {
      type: Number,
      default: 0
    },
    lastPlayTime: {
      type: Number,
      default: 0
    },
    textTrackUrl: {
      type: String,
      default: ''
    },
    webVtt: {
      type: String,
      default: ''
    },
    thumbnail: {
      type: Object,
      default: {}
    },
    urlList: {
      type: Array,
      default: []
    }
  },
  emits: ['reportedFlightData', 'reportedTime'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const flightData = ref("");
    const playData = reactive({
      player: {},
      flightData: {}
    });
    watch(() => props.videoUrl, newUrl => {
      console.log(newUrl);
      console.log(props.videoIndex);
      console.log(props.webVtt);
      playData.player.playNext({
        url: props.videoUrl,
        poster: props.poster,
        startTime: props.startTime
      });
      let track = playData.player.getPlugin('TextTrack');
      track.updateSubtitles([{
        id: 1,
        // 用于标识字幕的唯一性
        language: 'zh',
        // 语言
        text: "字幕1",
        isDefault: true,
        url: "./webvtt/" + props.webVtt
      }]);
    }, {
      deep: true
    });
    onMounted(() => {
      initPlayer();
    });

    // 初始化西瓜视频
    const initPlayer = () => {
      playData.player = new Player({
        id: props.id,
        plugins: [HlsPlugin, TextTrack],
        url: props.videoUrl,
        poster: props.poster,
        playsinline: props.playsinline,
        height: props.height,
        width: props.width,
        lastPlayTime: props.lastPlayTime,
        startTime: props.startTime,
        cssfullscreen: {
          disable: true
        },
        videoFillMode: "fill",
        screenShot: true,
        download: false,
        autoplay: true,
        textTrack: {
          position: 'root',
          style: {
            line: 'single',
            offsetBottom: -80
          },
          list: [{
            id: 1,
            // 用于标识字幕的唯一性
            language: 'zh',
            // 语言
            text: "字幕1",
            isDefault: true,
            url: "./webvtt/" + props.webVtt
          }
          /* {
             src: "./webvtt/"+props.webVtt,
             kind: "subtitles",
             label: "字幕1",
             srclang: "zh",
             default: true
           }*/]
        },

        autoplayMuted: true,
        playNext: {
          urlList: props.urlList
        },
        volume: {
          position: 'controlsLeft'
        },
        playbackRate: {
          position: 'controlsLeft',
          list: [8, 4, 3, 2, 1.5, 1.25, 1, 0.5]
        },
        thumbnail: props.thumbnail,
        whitelist: [""]
      });
      console.log(playData.player);
      let lastTime = 0;
      playData.player.on("timeupdate", event => {
        if (lastTime !== 0 && lastTime > event.currentTime) {
          emits('reportedTime', "moveTime_" + props.videoIndex);
        } else if (event.currentTime - lastTime > 20) {
          emits('reportedTime', "moveTime_" + props.videoIndex);
        } else {
          flightData.value = event.player._subTitles.innerRoot.innerText;
          if (flightData.value) {
            emits('reportedFlightData', flightData.value);
          }
        }
        lastTime = event.currentTime;
        if (event.duration > 0 && event.duration === event.currentTime) {
          emits('reportedTime', "endTime_" + playData.player.fullscreen + "_" + props.videoIndex);
        }
        //console.log(props.id)
        /* if (props.videoIndex.indexOf('pod')>-1  ){
           console.log(playData.player)
         }*/

        //console.log(event.player._subTitles.innerRoot.innerText)
      });
    };

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        style: {
          "width": "100%",
          "height": "100%"
        },
        id: __props.id
      }, null, 8, _hoisted_1);
    };
  }
};