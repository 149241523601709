/* unplugin-vue-components disabled */import __unplugin_components_2 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/Pagination/index.vue';
import __unplugin_components_1 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/DictTag/index.vue';
import __unplugin_components_0 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/RightToolbar/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, isRef as _isRef, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "app-container"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
import { listNotice, getNotice, delNotice, addNotice, updateNotice } from "@/api/system/notice";
export default {
  __name: 'index',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      sys_notice_status,
      sys_notice_type
    } = proxy.useDict("sys_notice_status", "sys_notice_type");
    const noticeList = ref([]);
    const open = ref(false);
    const loading = ref(true);
    const showSearch = ref(true);
    const ids = ref([]);
    const single = ref(true);
    const multiple = ref(true);
    const total = ref(0);
    const title = ref("");
    const data = reactive({
      form: {},
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        noticeTitle: undefined,
        createBy: undefined,
        status: undefined
      },
      rules: {
        noticeTitle: [{
          required: true,
          message: "公告标题不能为空",
          trigger: "blur"
        }],
        noticeType: [{
          required: true,
          message: "公告类型不能为空",
          trigger: "change"
        }]
      }
    });
    const {
      queryParams,
      form,
      rules
    } = toRefs(data);

    /** 查询公告列表 */
    function getList() {
      loading.value = true;
      listNotice(queryParams.value).then(response => {
        noticeList.value = response.rows;
        total.value = response.total;
        loading.value = false;
      });
    }
    /** 取消按钮 */
    function cancel() {
      open.value = false;
      reset();
    }
    /** 表单重置 */
    function reset() {
      form.value = {
        noticeId: undefined,
        noticeTitle: undefined,
        noticeType: undefined,
        noticeContent: undefined,
        status: "0"
      };
      proxy.resetForm("noticeRef");
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      queryParams.value.pageNum = 1;
      getList();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      proxy.resetForm("queryRef");
      handleQuery();
    }
    /** 多选框选中数据 */
    function handleSelectionChange(selection) {
      ids.value = selection.map(item => item.noticeId);
      single.value = selection.length != 1;
      multiple.value = !selection.length;
    }
    /** 新增按钮操作 */
    function handleAdd() {
      reset();
      open.value = true;
      title.value = "添加公告";
    }
    /**修改按钮操作 */
    function handleUpdate(row) {
      reset();
      const noticeId = row.noticeId || ids.value;
      getNotice(noticeId).then(response => {
        form.value = response.data;
        open.value = true;
        title.value = "修改公告";
      });
    }
    /** 提交按钮 */
    function submitForm() {
      proxy.$refs["noticeRef"].validate(valid => {
        if (valid) {
          if (form.value.noticeId != undefined) {
            updateNotice(form.value).then(response => {
              proxy.$modal.msgSuccess("修改成功");
              open.value = false;
              getList();
            });
          } else {
            addNotice(form.value).then(response => {
              proxy.$modal.msgSuccess("新增成功");
              open.value = false;
              getList();
            });
          }
        }
      });
    }
    /** 删除按钮操作 */
    function handleDelete(row) {
      const noticeIds = row.noticeId || ids.value;
      proxy.$modal.confirm('是否确认删除公告编号为"' + noticeIds + '"的数据项？').then(function () {
        return delNotice(noticeIds);
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    }
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      const _component_right_toolbar = __unplugin_components_0;
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_dict_tag = __unplugin_components_1;
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_2;
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_el_form, {
        model: _unref(queryParams),
        ref: "queryRef",
        inline: true,
        "label-width": "6.8rem"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "公告标题",
          prop: "noticeTitle"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).noticeTitle,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).noticeTitle = $event),
            placeholder: "请输入公告标题",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "操作人员",
          prop: "createBy"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).createBy,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).createBy = $event),
            placeholder: "请输入操作人员",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "类型",
          prop: "noticeType"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(queryParams).noticeType,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(queryParams).noticeType = $event),
            placeholder: "公告类型",
            clearable: ""
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_notice_type), dict => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: dict.value,
                label: dict.label,
                value: dict.value
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            icon: "Search",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }), _createVNode(_component_el_button, {
            icon: "Refresh",
            onClick: resetQuery
          }, {
            default: _withCtx(() => [_createTextVNode("重置")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), [[_vShow, _unref(showSearch)]]), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            plain: "",
            icon: "Plus",
            onClick: handleAdd
          }, {
            default: _withCtx(() => [_createTextVNode("新增")]),
            _: 1
          })), [[_directive_hasPermi, ['system:notice:add']]])]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "success",
            plain: "",
            icon: "Edit",
            disabled: _unref(single),
            onClick: handleUpdate
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['system:notice:edit']]])]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "danger",
            plain: "",
            icon: "Delete",
            disabled: _unref(multiple),
            onClick: handleDelete
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['system:notice:remove']]])]),
          _: 1
        }), _createVNode(_component_right_toolbar, {
          showSearch: _unref(showSearch),
          "onUpdate:showSearch": _cache[3] || (_cache[3] = $event => _isRef(showSearch) ? showSearch.value = $event : null),
          onQueryTable: getList
        }, null, 8, ["showSearch"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _unref(noticeList),
        onSelectionChange: handleSelectionChange
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "selection",
          width: "55",
          align: "center"
        }), _createVNode(_component_el_table_column, {
          label: "序号",
          align: "center",
          prop: "noticeId",
          width: "100"
        }), _createVNode(_component_el_table_column, {
          label: "公告标题",
          align: "center",
          prop: "noticeTitle",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "公告类型",
          align: "center",
          prop: "noticeType",
          width: "100"
        }, {
          default: _withCtx(scope => [_createVNode(_component_dict_tag, {
            options: _unref(sys_notice_type),
            value: scope.row.noticeType
          }, null, 8, ["options", "value"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "状态",
          align: "center",
          prop: "status",
          width: "100"
        }, {
          default: _withCtx(scope => [_createVNode(_component_dict_tag, {
            options: _unref(sys_notice_status),
            value: scope.row.status
          }, null, 8, ["options", "value"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "创建者",
          align: "center",
          prop: "createBy",
          width: "100"
        }), _createVNode(_component_el_table_column, {
          label: "创建时间",
          align: "center",
          prop: "createTime",
          width: "100"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.parseTime(scope.row.createTime, '{y}-{m}-{d}')), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作",
          align: "center",
          "class-name": "small-padding fixed-width"
        }, {
          default: _withCtx(scope => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            link: "",
            icon: "Edit",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:notice:edit']]]), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            link: "",
            icon: "Delete",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:notice:remove']]])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[4] || (_cache[4] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[5] || (_cache[5] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        title: _unref(title),
        modelValue: _unref(open),
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _isRef(open) ? open.value = $event : null),
        width: "78rem",
        "close-on-click-modal": false,
        "append-to-body": "",
        draggable: ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: cancel
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref: "noticeRef",
          model: _unref(form),
          rules: _unref(rules),
          "label-width": "8rem"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "公告标题",
                prop: "noticeTitle"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: _unref(form).noticeTitle,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(form).noticeTitle = $event),
                  placeholder: "请输入公告标题"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "公告类型",
                prop: "noticeType"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: _unref(form).noticeType,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).noticeType = $event),
                  placeholder: "请选择"
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_notice_type), dict => {
                    return _openBlock(), _createBlock(_component_el_option, {
                      key: dict.value,
                      label: dict.label,
                      value: dict.value
                    }, null, 8, ["label", "value"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "状态"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_radio_group, {
                  modelValue: _unref(form).status,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(form).status = $event)
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_notice_status), dict => {
                    return _openBlock(), _createBlock(_component_el_radio, {
                      key: dict.value,
                      label: dict.value
                    }, {
                      default: _withCtx(() => [_createTextVNode(_toDisplayString(dict.label), 1)]),
                      _: 2
                    }, 1032, ["label"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "内容"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  rows: 6,
                  type: "textarea",
                  placeholder: "请输入内容",
                  modelValue: _unref(form).noticeContent,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _unref(form).noticeContent = $event)
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["title", "modelValue"])]);
    };
  }
};