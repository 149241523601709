/* unplugin-vue-components disabled */import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4e8fbc4a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "left_captions_area",
  style: {
    "left": "unset",
    "right": "1%"
  }
};
const _hoisted_2 = {
  class: "row"
};
const _hoisted_3 = {
  class: "row"
};
const _hoisted_4 = {
  class: "row"
};
const _hoisted_5 = {
  class: "row"
};
const _hoisted_6 = {
  class: "row"
};
const _hoisted_7 = {
  class: "row"
};
const _hoisted_8 = {
  key: 1,
  class: "box-name"
};
const _hoisted_9 = {
  key: 2,
  class: "right_captions_area"
};
const _hoisted_10 = {
  key: 0,
  class: "right_captions_area_list"
};
const _hoisted_11 = ["onClick"];
const _hoisted_12 = {
  key: 0,
  class: "row"
};
const _hoisted_13 = {
  class: "onePlayer"
};
const _hoisted_14 = {
  style: {
    "width": "100%",
    "height": "100%"
  }
};
import VideoCard from "@/components/Card/VideoCard/index.vue";
import Player from "@/components/jessibuca/index.vue";
import XiGuaPlayer from "@/components/XGPlayer/xgPlayer.vue";
import TextCard from "@/components/Card/TextCard/index.vue";
import screenfull from "screenfull";
import usePermissionStore from "@/store/modules/permission";
export default {
  __name: 'onevideo',
  props: {
    oneVideo: {
      type: Object
    },
    // 是否显示提示
    isTasking: {
      type: Boolean,
      default: true
    },
    oneName: {
      type: String,
      default: ""
    }
  },
  emits: ["downloadMp4"],
  setup(__props, {
    expose: __expose,
    emit
  }) {
    const props = __props;
    const recentlyTaskId = inject("recentlyTaskId");
    const {
      proxy
    } = getCurrentInstance();
    const pageData = inject("pageData");
    const handleChildEvent = inject("handleChildEvent");
    const handleChildEventTimeBack = inject("handleChildEventTimeBack");
    const switchVideo = inject("switchVideo");
    const permission = usePermissionStore();
    const fullscreen = ref(false);
    const showSubtitle = ref(false);
    const controlelSize = ref("default");
    const aspectRatioState = ref(false);
    const videoStartRecord = ref(false);
    function onOneVideoCardSwitchChange() {
      showSubtitle.value = !showSubtitle.value;
    }
    function downloadMp4(taskID, a) {
      emit("downloadMp4", taskID, a);
    }
    const downloadProgressFormat = percentage => {
      return percentage === 100 ? '已完成' : `下载中`;
    };
    function onOneVideoCardFullScreenClick() {
      if (proxy.$refs["oneVideoRef"] === undefined) {
        return;
      }
      setTimeout(() => {
        requestAnimationFrame(() => {
          const dom = document.getElementsByClassName("one_video_card")[0];
          if (fullscreen.value) {
            screenfull.exit();
            fullscreen.value = false;
            proxy.$refs["oneVideoRef"].setJessibucaStyle(false);
          } else {
            screenfull.request(dom);
            fullscreen.value = true;
            proxy.$refs["oneVideoRef"].setJessibucaStyle(true);
          }
        });
      }, 50);
    }
    function onOneVideoCardVideoClick() {
      if (proxy.$refs["oneVideoRef"] === undefined) {
        return;
      }
      if (videoStartRecord.value) {
        proxy.$refs["oneVideoRef"].videoStopRecordAndSave();
        videoStartRecord.value = false;
      } else {
        proxy.$refs["oneVideoRef"].videoStartRecord();
        videoStartRecord.value = true;
        proxy.$modal.msgWarning("开始录屏，再次点击结束录屏");
      }
    }
    function onOneVideoCardScreenCutClick() {
      if (proxy.$refs["oneVideoRef"] === undefined) {
        return;
      }
      proxy.$refs["oneVideoRef"].videoScreenShot();
    }
    function onOneVideoCardReduction() {
      if (proxy.$refs["oneVideoRef"] === undefined) {
        return;
      }
      aspectRatioState.value = !aspectRatioState.value;
      proxy.$refs["oneVideoRef"].setJessibucaStyle(aspectRatioState.value);
    }
    watch(() => props.oneVideo.controlelSize, newVal => {
      if (newVal) {
        controlelSize.value = props.oneVideo.controlelSize;
      }
    });
    function updateviewer(inClass) {
      const onevideocard = document.querySelector(".one_video_card");
      const onePlayer = document.querySelector(".onePlayer");
      onePlayer.style.background = "none";
      onevideocard.style.display = "flex";
      if (inClass === "minTalking") {
        onevideocard.style.top = "unset";
        onevideocard.style.background = "unset";
        onevideocard.style.padding = "unset";
      } else if (inClass === "maxTalking") {
        onevideocard.style.background = "unset";
        onevideocard.style.top = "unset";
        onevideocard.style.padding = "unset";
        if (!(pageData.playerShow && pageData.deviceVideo.oneUrl !== '')) {
          onePlayer.style.background = "rgba(9, 15, 27)";
        }
      } else if (inClass === "notShowMin") {
        if (pageData.taskPlay === "minTalking") {
          onevideocard.style.display = "none";
        }
      } else if (inClass === "showMin") {
        if (!(pageData.playerShow && pageData.deviceVideo.oneUrl !== '')) {
          onePlayer.style.background = "rgba(9, 15, 27)";
        }
      }
    }
    __expose({
      updateviewer
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_progress = _resolveComponent("el-progress");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      return _openBlock(), _createBlock(VideoCard, {
        ref: "oneVideoCardRef",
        "show-subtitle": _unref(showSubtitle),
        "controlel-size": _unref(controlelSize),
        "show-switch": __props.isTasking,
        "show-full-screen": __props.isTasking,
        "show-video-camera": __props.isTasking,
        "show-picture-outlined": __props.isTasking,
        "show-aspect-ratio": __props.isTasking,
        "aspect-ratio-state": _unref(aspectRatioState),
        onSwitchChange: onOneVideoCardSwitchChange,
        onFullScreenClick: onOneVideoCardFullScreenClick,
        onVideoClick: onOneVideoCardVideoClick,
        onScreenCutClick: onOneVideoCardScreenCutClick,
        onReduction: onOneVideoCardReduction,
        class: "one_video_card"
      }, {
        default: _withCtx(() => [_unref(showSubtitle) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, "俯仰角度: " + _toDisplayString(), 1), _createElementVNode("div", _hoisted_3, "目标高度: " + _toDisplayString(), 1), _createElementVNode("div", _hoisted_4, "图像跟踪状态: " + _toDisplayString(), 1), _createElementVNode("div", _hoisted_5, "目标经度: " + _toDisplayString(), 1), _createElementVNode("div", _hoisted_6, "目标纬度: " + _toDisplayString(), 1), _createElementVNode("div", _hoisted_7, "方位角度: " + _toDisplayString(), 1)])) : _createCommentVNode("", true), _unref(pageData).viewerClass === 'minTalking' ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(TextCard, {
          ref: "mapVideoTextRef",
          text: __props.oneName,
          "font-size": "2rem",
          color: "#FFFFFF",
          padding: "0"
        }, null, 8, ["text"])])) : _createCommentVNode("", true), _unref(pageData).recordVideo.podList.length > 0 && _unref(pageData).playerShow ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = $event => _unref(pageData).recordListShow.seven = !_unref(pageData).recordListShow.seven),
          class: "row"
        }, [_createVNode(_component_el_button, {
          style: {
            "width": "10.1rem"
          },
          size: "small"
        }, {
          default: _withCtx(() => [_createTextVNode("录像列表")]),
          _: 1
        })]), _unref(pageData).recordListShow.seven ? (_openBlock(), _createElementBlock("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pageData).recordVideo.podList, (podInfo, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: "row",
            key: index,
            onClick: $event => _unref(switchVideo)(index, 1)
          }, [podInfo.liveRecordStartTime === _unref(pageData).recordVideo.podIndex ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            size: "small",
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(podInfo.liveRecordStartTime) + "—" + _toDisplayString(podInfo.liveRecordStopTime), 1)]),
            _: 2
          }, 1024)) : (_openBlock(), _createBlock(_component_el_button, {
            key: 1,
            size: "small",
            type: "info"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(podInfo.liveRecordStartTime) + "—" + _toDisplayString(podInfo.liveRecordStopTime), 1)]),
            _: 2
          }, 1024))], 8, _hoisted_11);
        }), 128))])) : _createCommentVNode("", true), _withDirectives((_openBlock(), _createElementBlock("div", null, [_unref(permission).downloadMp4.indexOf(_unref(pageData).recentlyTaskInfo.taskName + 'pod') > -1 ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(_component_el_progress, {
          style: {
            "margin-left": "-0.6rem",
            "color": "#0d0d0d"
          },
          indeterminate: true,
          "text-inside": true,
          format: downloadProgressFormat,
          "stroke-width": 24,
          percentage: _unref(pageData).downloadProgressPercentage,
          status: "success"
        }, null, 8, ["percentage"])])) : (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = $event => downloadMp4(_unref(recentlyTaskId), 'pod')),
          class: "row"
        }, [_createVNode(_component_el_button, {
          style: {
            "width": "10.1rem"
          },
          size: "small"
        }, {
          default: _withCtx(() => [_createTextVNode("录像下载")]),
          _: 1
        })]))])), [[_directive_hasPermi, ['drone:task:download']]])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_13, [_unref(pageData).deviceVideo.oneUrl !== '' && _unref(pageData).recordVideo.podUrl === '' ? _withDirectives((_openBlock(), _createBlock(Player, {
          key: 0,
          ref: "oneVideoRef",
          url: _unref(pageData).deviceVideo.oneUrl,
          "flv-url": _unref(pageData).deviceVideo.podFlvUrl,
          fluent: "",
          autoplay: ""
        }, null, 8, ["url", "flv-url"])), [[_vShow, _unref(pageData).playerShow]]) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", _hoisted_14, [_createVNode(XiGuaPlayer, {
          ref: "podVideoRec",
          onReportedFlightData: _unref(handleChildEvent),
          onReportedTime: _unref(handleChildEventTimeBack),
          id: 'app7',
          "video-index": _unref(pageData).recordVideo.podId,
          "video-url": _unref(pageData).recordVideo.podUrl,
          "start-time": _unref(pageData).recordVideo.podPlayTime,
          "web-vtt": _unref(pageData).recordVideo.webVtt
        }, null, 8, ["onReportedFlightData", "onReportedTime", "video-index", "video-url", "start-time", "web-vtt"])], 512), [[_vShow, _unref(pageData).playerShow && _unref(pageData).recordVideo.podUrl !== '']])])]),
        _: 1
      }, 8, ["show-subtitle", "controlel-size", "show-switch", "show-full-screen", "show-video-camera", "show-picture-outlined", "show-aspect-ratio", "aspect-ratio-state"]);
    };
  }
};