import request from '@/utils/request'

export function getFieldTemplate(type) {
    return request({
        url: '/device/template/type/' + type,
        method: 'get'
    })
}

export function addFieldTemplate(data) {
    return request({
        url: '/device/template/addField',
        method: 'post',
        data: data
    })
}
export function deleteField(field,type) {
    return request({
        url: '/device/template/'+field+'/'+type,
        method: 'delete',
    })
}

export function queryOption(field,type) {
    return request({
        url: '/device/template/option/'+field+'/'+type,
        method: 'get',
    })
}

export function queryFieldTemplate(type) {
    return request({
        url: '/device/template/'+type,
        method: 'get',
    })
}
