import request from '@/utils/request'
import { parseStrEmpty } from "@/utils/satpro";

// 查询列表
export function listProject (query) {
  return request({
    url: '/alarm/project/list',
    method: 'get',
    params: query
  })
}

// 查询识别方案详细
export function getProject (projectId) {
  return request({
    url: '/alarm/project/' + parseStrEmpty(projectId),
    method: 'get'
  })
}

// 新增识别方案
export function addProject (data) {
  return request({
    url: '/alarm/project/add',
    method: 'post',
    data: data
  })
}

// 修改识别方案
export function updateProject (data) {
  return request({
    url: '/alarm/project/update',
    method: 'put',
    data: data
  })
}

// 删除识别方案
export function delProject (projectId) {
  return request({
    url: '/alarm/project/' + projectId,
    method: 'delete'
  })
}

// 查询系统默认告警目标
export function defaultalarmtype () {
  return request({
    url: '/alarm/info/getRecognitionList',
    method: 'get'
  })
}

// 查询所有识别方案明细
export function selectProjectReportnoList () {
  return request({
    url: '/alarm/droneproject/reportno/list',
    method: 'get'
  })
}

// 修改识别方案明细
export function addorupdateprojectreportno (data) {
  return request({
    url: '/alarm/droneproject/reportno/addOrUpdate',
    method: 'post',
    data: data
  })
}

// 查询识别方案用户列表
export function getProjectUserlist () {
  return request({
    url: '/alarm/project/getProjectUserlist',
    method: 'get'
  })
}






