/*
*这个方法是获取周对应的日期范围(常规的一周为周一到周天为一周
* 参数datevalue如：2017-01-01)
*/
export function getDateRange(datevalue){
    //日期转字符串格式
    function DateToStr(date) {
        var year = date.getFullYear();//年
        var month = date.getMonth();//月
        var day = date.getDate();//日
        return year + "-" +
            ((month + 1) > 9 ? (month + 1) : "0" + (month + 1)) + "-" +
            (day > 9 ? day : ("0" + day));
    }
    var dateValue = DateToStr(datevalue);
    var arr = dateValue.split("-")
    //月份-1 因为月份从0开始 构造一个Date对象
    var date = new Date(arr[0],arr[1]-1,arr[2]);

    var dateOfWeek = date.getDay();//返回当前日期的在当前周的某一天（0～6--周日到周一）

    var dateOfWeekInt = parseInt(dateOfWeek,10);//转换为整型

    if(dateOfWeekInt==0){//如果是周日
        dateOfWeekInt=7;
    }
    var aa = 7-dateOfWeekInt;//当前于周末相差的天数

    var temp2 = parseInt(arr[2],10);//按10进制转换，以免遇到08和09的时候转换成0
    var sunDay = temp2+aa;//当前日期的周日的日期
    var monDay = sunDay-6//当前日期的周一的日期

    var startDate = new Date(arr[0],arr[1]-1,monDay, 0, 0, 0);
    var endDate = new Date(arr[0],arr[1]-1,sunDay, 23, 59, 59);

    /*var sm = parseInt(startDate.getMonth())+1;//月份+1 因为月份从0开始
    var em = parseInt(endDate.getMonth())+1;

    var start = startDate.getFullYear()+"-"+sm+"-"+startDate.getDate();
    var end = endDate.getFullYear()+"-"+em+"-"+endDate.getDate();*/
    var result = new Array();
    result.push(startDate);
    result.push(endDate);

    return result;
}


/** 获取指定日期的月初和月末(默认当前月)
 * @param e 需要指定的日期(格式：yyyy-MM 或 yyyy/MM 或 yyyy.MM)
 * @returns {{end: string, first: string}} 默认返回当前月的月初和月末日期
 */
export function getSpecifyEarlyEndMonth(e = new Date()) {
    const nowDate = new Date(e), // 当前日期
        year = nowDate.getFullYear(), // 指定年
        month = nowDate.getMonth() + 1, // 指定月
        //oneDayTime = 24 * 60 * 60 * 1000, // 一天的毫秒(ms)数
        oneDayTime = 1000,
        startDate = new Date(year, month - 1), // 当月第一天的日期
        endDate = new Date(new Date(year, month).valueOf() - oneDayTime); // 当月最后一天的日期
    /*function dataFormat(d) {
        const Y = d.getFullYear(), // 年
            M = prefixZero(d.getMonth() + 1), // 月
            D = prefixZero(d.getDate()); // 日
        return Y + "-" + M + "-" + D;
    }
    function prefixZero(num = 0, n = 2) {
        return (Array(n).join("0") + num).slice(-n);
    }*/
    var result = new Array();
    result.push(startDate);
    result.push(endDate);
    return result;
}


/** 获取指定年份季度的开始和结束日期(默认今年的第一季度)
 * @param year 指定的年份
 * @param qtr 指定的季度
 * @returns {{end: string, first: string}} 默认返回今年第一季度的日期
 */
export function getSpecifyQuarter(year = new Date().getFullYear(), qtr = 1) {
    let val = 0; // 第一季度
    if (qtr === 2) {
        val = 3; // 第二季度
    } else if (qtr === 3) {
        val = 6; // 第三季度
    } else if (qtr === 4) {
        val = 9; // 第四季度
    }
    const startDate = new Date(year, val, 1);
    const endDate = new Date(year, val + 2, getMonthDays(year, val + 2));
    // 日期格式化
    function dataFormat(d) {
        const Y = d.getFullYear(), // 年
            M = prefixZero(d.getMonth() + 1), // 月
            D = prefixZero(d.getDate()); // 日
        return Y + "-" + M + "-" + D;
    }
    // 数字位数不够，数字前面补零
    function prefixZero(num = 0, n = 2) {
        return (Array(n).join("0") + num).slice(-n);
    }
    // 获得指定月份的天数
    function getMonthDays(y, m) {
        const monthStart = new Date(y, m, 1);
        const monthEnd = new Date(y, m + 1, 1);
        return (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
    }
    return { first: dataFormat(startDate), end: dataFormat(endDate) };
}


export function getSpecifyYear(e = new Date()) {
    const nowDate = new Date(e), // 当前日期
        year = nowDate.getFullYear(); // 指定年
    const startDate = new Date(year, 0, 1);
    const endDate = new Date(year, 11, 31, 23, 59, 59);
    var result = new Array();
    result.push(startDate);
    result.push(endDate);
    return result;
}

export function getDefaultDate(e = new Date()) {
    let nowDate = new Date(e), // 当前日期
        y = nowDate.getFullYear(), // 指定年
        m = nowDate.getMonth() + 1,
        d = nowDate.getDate() - 6;
    let startDate;
    if (d < 0) {
        m = nowDate.getMonth();
        var d1 = new Date(y, m, 0);
        var d2 = d1.getDate();
        startDate =  new Date(y, m-1 , (d2 + d));
    } else {
        startDate = new Date(y, m-1, d);
    }
    let endDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 23, 59, 59);
    var result = new Array();
    result.push(startDate);
    result.push(endDate);
    return result;
}
