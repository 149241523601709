/* unplugin-vue-components disabled */import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { updateUserPwd } from "@/api/system/user";
import useUserStore from '@/store/modules/user';
export default {
  __name: 'resetPwd',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const userStore = useUserStore();
    const user = reactive({
      oldPassword: undefined,
      newPassword: undefined,
      confirmPassword: undefined
    });
    const validatePass = (rule, value, callback) => {
      //必须包含⼤⼩写字母、数字、特殊字符长度再8-20位之间
      var regex = new RegExp("(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}");
      if (value.length < 8 || value.length > 20) {
        callback(new Error("请输⼊8~20位密码"));
      } else if (!regex.test(value)) {
        callback(new Error("密码必须同时包含字母、数字和特殊字符其中三项且⾄少8位"));
      } else {
        callback();
      }
    };
    const equalToPassword = (rule, value, callback) => {
      if (user.newPassword !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    const rules = ref({
      oldPassword: [{
        required: true,
        message: "旧密码不能为空",
        trigger: "blur"
      }],
      newPassword: [{
        required: true,
        message: "新密码不能为空",
        trigger: "blur"
      }, {
        required: true,
        validator: validatePass,
        trigger: "blur"
      }],
      confirmPassword: [{
        required: true,
        message: "确认密码不能为空",
        trigger: "blur"
      }, {
        required: true,
        validator: equalToPassword,
        trigger: "blur"
      }]
    });

    /** 提交按钮 */
    function submit() {
      proxy.$refs.pwdRef.validate(valid => {
        if (valid) {
          updateUserPwd(user.oldPassword, user.newPassword).then(response => {
            proxy.$modal.msgSuccess("修改成功");
            userStore.userSynapse = user.newPassword;
          });
        }
      });
    }
    ;
    /** 关闭按钮 */
    function close() {
      proxy.$router.back();
    }
    ;
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createBlock(_component_el_form, {
        ref: "pwdRef",
        model: _unref(user),
        rules: _unref(rules),
        "label-width": "10rem"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "旧密码",
          prop: "oldPassword"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(user).oldPassword,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(user).oldPassword = $event),
            modelModifiers: {
              trim: true
            },
            placeholder: "请输入旧密码",
            type: "password",
            "show-password": ""
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "新密码",
          prop: "newPassword"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(user).newPassword,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(user).newPassword = $event),
            modelModifiers: {
              trim: true
            },
            placeholder: "请输入新密码",
            type: "password",
            "show-password": ""
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "确认密码",
          prop: "confirmPassword"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(user).confirmPassword,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(user).confirmPassword = $event),
            modelModifiers: {
              trim: true
            },
            placeholder: "请确认密码",
            type: "password",
            "show-password": ""
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            class: "satpro-button",
            onClick: submit
          }, {
            default: _withCtx(() => [_createTextVNode("保存")]),
            _: 1
          }), _createVNode(_component_el_button, {
            class: "satpro-button",
            onClick: close
          }, {
            default: _withCtx(() => [_createTextVNode("关闭")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "rules"]);
    };
  }
};