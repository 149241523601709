/* unplugin-vue-components disabled */import __unplugin_components_1 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/Pagination/index.vue';
import __unplugin_components_0 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/DictTag/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d8d56efc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "height": "56rem"
  }
};
const _hoisted_2 = {
  class: "dialog-footer"
};
import { authUserSelectAll, unallocatedUserList } from "@/api/system/role";
export default {
  __name: 'selectUser',
  props: {
    roleId: {
      type: [Number, String]
    }
  },
  emits: ["ok"],
  setup(__props, {
    expose: __expose,
    emit
  }) {
    const props = __props;
    const {
      proxy
    } = getCurrentInstance();
    const {
      sys_normal_disable
    } = proxy.useDict("sys_normal_disable");
    const userList = ref([]);
    const visible = ref(false);
    const total = ref(0);
    const userIds = ref([]);
    const queryParams = reactive({
      pageNum: 1,
      pageSize: 10,
      roleId: undefined,
      userName: undefined,
      phonenumber: undefined
    });

    // 显示弹框
    function show() {
      queryParams.roleId = props.roleId;
      getList();
      visible.value = true;
    }
    /**选择行 */
    function clickRow(row) {
      proxy.$refs["refTable"].toggleRowSelection(row);
    }
    // 多选框选中数据
    function handleSelectionChange(selection) {
      userIds.value = selection.map(item => item.userId);
    }
    // 查询表数据
    function getList() {
      unallocatedUserList(queryParams).then(res => {
        userList.value = res.rows;
        total.value = res.total;
      });
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      queryParams.pageNum = 1;
      getList();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      proxy.resetForm("queryRef");
      handleQuery();
    }

    /** 选择授权用户操作 */
    function handleSelectUser() {
      const roleId = queryParams.roleId;
      const uIds = userIds.value.join(",");
      if (uIds == "") {
        proxy.$modal.msgError("请选择要分配的用户");
        return;
      }
      authUserSelectAll({
        roleId: roleId,
        userIds: uIds
      }).then(res => {
        proxy.$modal.msgSuccess(res.msg);
        if (res.code === 200) {
          visible.value = false;
          emit("ok");
        }
      });
    }
    __expose({
      show
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_dict_tag = __unplugin_components_0;
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_1;
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        title: "选择用户",
        modelValue: _unref(visible),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _isRef(visible) ? visible.value = $event : null),
        width: "100rem",
        "close-on-click-modal": false,
        "append-to-body": "",
        draggable: ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: handleSelectUser
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[4] || (_cache[4] = $event => visible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          ref: "queryRef",
          inline: true
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "用户名称",
            prop: "userName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).userName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).userName = $event),
              placeholder: "请输入用户名称",
              clearable: "",
              onKeyup: _withKeys(handleQuery, ["enter"])
            }, null, 8, ["modelValue", "onKeyup"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "手机号码",
            prop: "phonenumber"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).phonenumber,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).phonenumber = $event),
              placeholder: "请输入手机号码",
              clearable: "",
              onKeyup: _withKeys(handleQuery, ["enter"])
            }, null, 8, ["modelValue", "onKeyup"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              class: "satpro-button",
              icon: "Search",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            }), _createVNode(_component_el_button, {
              class: "satpro-button",
              icon: "Refresh",
              onClick: resetQuery
            }, {
              default: _withCtx(() => [_createTextVNode("重置")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_table, {
          onRowClick: clickRow,
          ref: "refTable",
          data: _unref(userList),
          onSelectionChange: handleSelectionChange,
          "header-cell-style": {
            background: 'transparent !important',
            color: '#FFFFFF'
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            type: "selection",
            width: "55"
          }), _createVNode(_component_el_table_column, {
            label: "用户名称",
            prop: "userName",
            "show-overflow-tooltip": true
          }), _createVNode(_component_el_table_column, {
            label: "用户昵称",
            prop: "nickName",
            "show-overflow-tooltip": true
          }), _createVNode(_component_el_table_column, {
            label: "邮箱",
            prop: "email",
            "show-overflow-tooltip": true
          }), _createVNode(_component_el_table_column, {
            label: "手机",
            prop: "phonenumber",
            "show-overflow-tooltip": true
          }), _createVNode(_component_el_table_column, {
            label: "状态",
            align: "center",
            prop: "status"
          }, {
            default: _withCtx(scope => [_createVNode(_component_dict_tag, {
              options: _unref(sys_normal_disable),
              value: scope.row.status
            }, null, 8, ["options", "value"])]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "创建时间",
            align: "center",
            prop: "createTime",
            width: "240"
          }, {
            default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.parseTime(scope.row.createTime)), 1)]),
            _: 1
          })]),
          _: 1
        }, 8, ["data"]), _withDirectives(_createVNode(_component_pagination, {
          total: _unref(total),
          page: _unref(queryParams).pageNum,
          "onUpdate:page": _cache[2] || (_cache[2] = $event => _unref(queryParams).pageNum = $event),
          limit: _unref(queryParams).pageSize,
          "onUpdate:limit": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageSize = $event),
          onPagination: getList
        }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]])])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};