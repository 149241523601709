/* unplugin-vue-components disabled */import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3a4e4fd8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "player-container"
};
const _hoisted_2 = ["id"];
const _hoisted_3 = {
  key: 0,
  class: "noVideo"
};
export default {
  __name: 'index',
  props: {
    url: {
      type: String,
      // default: "ws://113.200.202.153:10018/live/zhrwc3.live.flv"
      default: ""
    },
    flvUrl: {
      type: String,
      // default: "ws://113.200.202.153:10018/live/zhrwc3.live.flv"
      default: ""
    },
    close: {
      type: Boolean,
      default: false
    },
    /**
     * 宽高比
     */
    aspectRatio: {
      type: String,
      default: "16/9"
    }
  },
  setup(__props, {
    expose: __expose
  }) {
    const props = __props;
    let i = 0;
    const urlUsable = ref(true);
    const sangedian = ref('。。。');
    const sgd = ref({});
    const flag = ref(true);
    const intervarTime = ref(undefined);
    watch(props.url, newValue => {
      reInitAndPlay();
    });
    watch(props.aspectRatio, newValue => {
      setJessibucaStyle(true);
    });
    const {
      proxy
    } = getCurrentInstance();
    const jessibucaPlayer = ref(null);
    const containerId = ref("webplayer_" + parseInt(Math.random() * 100000000) + Date.now());
    const showOperateBtns = ref(true);
    const forceNoOffscreen = ref(true);
    const noVideoStatus = ref(false);
    const jessibucaStyle = ref({});
    const title = ref("");
    __expose({
      videoScreenShot,
      setJessibucaStyle,
      videoStartRecord,
      videoStopRecordAndSave,
      videodestroy,
      videoFullScreen,
      play,
      pause
    });
    onMounted(() => {
      setJessibucaStyle(false);
      reInitAndPlay();
    });

    // 初始化播放器
    function initPlayer() {
      jessibucaPlayer.value = new JessibucaProMulti({
        container: document.getElementById(containerId.value),
        videoBuffer: 0.2,
        // 设置最小缓冲时长，单位秒，播放器会自动消除延迟。
        videoBufferDelay: 1,
        //设置最大缓冲延迟时长，单位秒，最小不能低于1秒，如果延迟超过该值，就会进行丢帧处理。
        useSIMD: true,
        //是否使用SIMD 软解码，仅仅wasm软解码的时候有效
        isResize: false,
        //1. 当为`true`的时候：视频画面做等比缩放后,高或宽对齐canvas区域,画面不被拉伸,但有黑边。 等同于 `setScaleMode(1)`2. 当为`false`的时候：视频画面完全填充canvas区域,画面会被拉伸。等同于 `setScaleMode(0)`
        text: "",
        loadingText: "加载中",
        //加载过程中文案
        useMSE: true,
        //是否开启MediaSource硬解码
        demuxUseWorker: true,
        //硬解码(MediaSource，Webcodec)worker解封装
        isMulti: true,
        //当多路播放的时候，可以配置起来，这样在看日志的时候，会有一个uuid来区分
        debug: false,
        //是否开启控制台调试打印
        debugLevel: "debug",
        showBandwidth: true,
        // 显示网速
        heartTimeout: 10,
        heartTimeoutReplay: true,
        heartTimeoutReplayTimes: -1,
        //想无限次重试，可以设置为-1
        loadingTimeout: 10,
        loadingTimeoutReplay: true,
        loadingTimeoutReplayTimes: -1,
        //想无限次重试，可以设置为-1
        replayUseLastFrameShow: true,
        //触发重播的时候，是否使用最后一帧来显示
        hasVideo: true,
        hasAudio: false,
        useVideoRender: true,
        //showPerformance: true, // 显示性能
        replayShowLoadingIcon: true,
        // 重播显示loading
        pauseAndNextPlayUseLastFrameShow: true,
        //调用 `pause()` 方法之后，再次调用`play()`方法，在加载画面的时候，显示上一帧画面，而不是黑屏
        heartTimeoutReplayUseLastFrameShow: true,
        //播放过程中，超时重连时，是否使用最后一帧来显示
        operateBtns: {
          fullscreen: false,
          //是否显示全屏按钮
          screenshot: false,
          //是否显示截图按钮
          play: showOperateBtns.value,
          //是否显示播放暂停按钮
          audio: false,
          //是否显示声音按钮
          recorder: false,
          //是否显示录制按钮
          close: props.close //是否显示关闭按钮
        },

        forceNoOffscreen: forceNoOffscreen.value,
        //是否不使用离屏模式（提升渲染能力）
        isNotMute: false,
        //是否开启声音，默认是关闭声音播放的。
        decoder: '/jessibuca-pro-release/js/decoder-pro.js'
      });
      jessibucaPlayer.value.on(JessibucaProMulti.EVENTS.stats, (index, stats) => {
        let fps = stats.fps;

        /*    if (fps === 0){
              i++
              console.log(i)
              console.log(urlUsable.value)
              if (i === 15 && urlUsable.value){
                urlUsable.value = false
                reInitAndPlay()
              }
        
            }*/
        if (fps < 15) {
          title.value = "当前网络环境差";
        } else if (fps > 15 && fps <= 24) {
          title.value = "当前网络环境良好";
        } else if (fps > 24) {
          title.value = "当前网络环境非常好";
        }
      });
      jessibucaPlayer.value.on(JessibucaProMulti.EVENTS.playFailedAndPaused, (index, error, code) => {});
      jessibucaPlayer.value.on(JessibucaProMulti.EVENTS.error, (index, error, code) => {
        console.log(index);
        console.log("错误");
        console.log(error);
        console.log(code);
        if (error === 'aliyunRtcError') {
          if (code.extraInfo.response.status === 400 || code.extraInfo.response.status === 500) {
            //proxy.$modal.msgWarning("不满足低延时标准，切换标准直播");
            urlUsable.value = false;
            reInitAndPlay();
          } else if (code.extraInfo.response.status === 404) {
            urlUsable.value = true;
            noVideoStatus.value = true;
            setTimeout(play, 2000);
            clearInterval(sgd.value);
            sgd.value = setInterval(() => {
              sangedian.value = sangedian.value === '。。。' ? '。' : sangedian.value === '。' ? '。。' : '。。。';
            }, 500);
            //reInitAndPlay()
          } else {
            urlUsable.value = false;
            reInitAndPlay();
          }
        } else if (error === 'mediaSourceH265NotSupport') {
          console.log("软解码错误启用硬解码");
        } else {
          urlUsable.value = true;
          reInitAndPlay();
        }
      });
    }
    watch(() => title.value, (newVal, oldVal) => {
      if (flag.value) {
        intervarTime.value = new Date().getTime() + 1000 * 60 * 3;
        flag.value = false;
      }
      if (intervarTime.value <= new Date().getTime()) {
        //保留半小时内的轨迹，大于半小时每两分钟清除一段
        if (newVal === "当前网络环境差") {
          proxy.$modal.msgWarning(newVal);
        }
        flag.value = true;
        intervarTime.value = undefined;
      }
    });
    function setJessibucaStyle(e) {
      let style = {};
      if (!e) {
        style.width = '100%';
        style.height = '100%';
      } else {
        style.width = 'auto';
        style.height = '100%';
        style.aspectRatio = "16/9";
      }
      jessibucaStyle.value = style;
    }
    function replay() {
      if (jessibucaPlayer.value) {
        jessibucaPlayer.value.destroy().then(() => {
          reInitAndPlay();
        });
      } else {
        reInitAndPlay();
      }
    }

    // 播放
    function play() {
      if (urlUsable.value) {
        console.log("播放wertc");
        console.log(props.url);
        jessibucaPlayer.value.play(props.url).then(res => {
          noVideoStatus.value = false;
          clearInterval(sgd.value);
        });
      } else {
        console.log("降级播放flv");
        console.log(props.flvUrl);
        jessibucaPlayer.value.play(props.flvUrl).then(res => {
          noVideoStatus.value = false;
          clearInterval(sgd.value);
        });
      }
      playerLoad();
    }

    //播放器重新初始化播放
    function reInitAndPlay() {
      i = 0;
      if (jessibucaPlayer.value) {
        jessibucaPlayer.value.destroy().then(() => {
          initPlayer();
          play();
        });
      } else {
        initPlayer();
        play();
      }
    }
    function pause() {
      if (jessibucaPlayer.value) {
        jessibucaPlayer.value.pause();
        console.log("我停止播放");
      }
    }
    function playerLoad() {
      if (jessibucaPlayer.value) {
        jessibucaPlayer.value.on("load", function () {
          console.log('load');
        });
      } else {
        reInitAndPlay();
      }
    }

    /**
     * 暂停流播发并销毁播放器
     */
    function pauseVideoStreamAndDestoryPlayer() {
      if (jessibucaPlayer.value) {
        jessibucaPlayer.value.pause();
        jessibucaPlayer.value.destroy();
      }
    }
    function videodestroy() {
      jessibucaPlayer.value.destroy();
    }
    // 视频截图功能
    function videoScreenShot() {
      jessibucaPlayer.value.screenshot(new Date().getTime());
    }

    // 录屏
    function videoStartRecord() {
      jessibucaPlayer.value.startRecord(new Date().getTime(), "mp4");
    }
    function videoStopRecordAndSave() {
      jessibucaPlayer.value.stopRecordAndSave("download", new Date().getTime());
    }

    // 全屏
    function videoFullScreen() {
      jessibucaPlayer.value.setFullscreen(true);
    }
    onBeforeUnmount(() => {
      pauseVideoStreamAndDestoryPlayer();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        id: _unref(containerId),
        style: _normalizeStyle(_unref(jessibucaStyle))
      }, null, 12, _hoisted_2), _unref(noVideoStatus) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("p", null, "未发现视频源,持续尝试连接中" + _toDisplayString(_unref(sangedian)), 1)])) : _createCommentVNode("", true)]);
    };
  }
};