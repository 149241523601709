/* unplugin-vue-components disabled */import { unref as _unref, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = ["src"];
export default {
  __name: 'index',
  props: {
    src: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const height = ref(document.documentElement.clientHeight - 94.5 + "px;");
    const loading = ref(true);
    const url = computed(() => props.src);
    onMounted(() => {
      setTimeout(() => {
        loading.value = false;
      }, 300);
      window.onresize = function temp() {
        height.value = document.documentElement.clientHeight - 94.5 + "px;";
      };
    });
    return (_ctx, _cache) => {
      const _directive_loading = _resolveDirective("loading");
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        style: _normalizeStyle('height:' + _unref(height))
      }, [_createElementVNode("iframe", {
        src: _unref(url),
        frameborder: "no",
        style: {
          "width": "100%",
          "height": "100%"
        },
        scrolling: "auto"
      }, null, 8, _hoisted_1)], 4)), [[_directive_loading, _unref(loading)]]);
    };
  }
};