import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from "vue";
export default {
  __name: 'index',
  setup(__props) {
    const url = ref(process.env.VUE_APP_BASE_API + "/druid/login.html");
    return (_ctx, _cache) => {
      const _component_i_frame = _resolveComponent("i-frame");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_i_frame, {
        src: url.value,
        "onUpdate:src": _cache[0] || (_cache[0] = $event => url.value = $event)
      }, null, 8, ["src"])]);
    };
  }
};