/* unplugin-vue-components disabled */import __unplugin_components_2 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/Pagination/index.vue';
import __unplugin_components_1 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/DictTag/index.vue';
import __unplugin_components_0 from 'D:/woker/shikongshuju/xinbanweb/space-time-web/src/components/RightToolbar/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, isRef as _isRef, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "app-container"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  class: "dialog-footer"
};
import { getJob } from "@/api/monitor/job";
import { listJobLog, delJobLog, cleanJobLog } from "@/api/monitor/jobLog";
export default {
  __name: 'log',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      sys_common_status,
      sys_job_group
    } = proxy.useDict("sys_common_status", "sys_job_group");
    const jobLogList = ref([]);
    const open = ref(false);
    const loading = ref(true);
    const showSearch = ref(true);
    const ids = ref([]);
    const multiple = ref(true);
    const total = ref(0);
    const dateRange = ref([]);
    const route = useRoute();
    const data = reactive({
      form: {},
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        dictName: undefined,
        dictType: undefined,
        status: undefined
      }
    });
    const {
      queryParams,
      form,
      rules
    } = toRefs(data);

    /** 查询调度日志列表 */
    function getList() {
      loading.value = true;
      listJobLog(proxy.addDateRange(queryParams.value, dateRange.value)).then(response => {
        jobLogList.value = response.rows;
        total.value = response.total;
        loading.value = false;
      });
    }
    // 返回按钮
    function handleClose() {
      const obj = {
        path: "/monitor/job"
      };
      proxy.$router.push(obj);
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      queryParams.value.pageNum = 1;
      getList();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      dateRange.value = [];
      proxy.resetForm("queryRef");
      handleQuery();
    }
    // 多选框选中数据
    function handleSelectionChange(selection) {
      ids.value = selection.map(item => item.jobLogId);
      multiple.value = !selection.length;
    }
    /** 详细按钮操作 */
    function handleView(row) {
      open.value = true;
      form.value = row;
    }
    /** 删除按钮操作 */
    function handleDelete(row) {
      proxy.$modal.confirm('是否确认删除调度日志编号为"' + ids.value + '"的数据项?').then(function () {
        return delJobLog(ids.value);
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    }
    /** 清空按钮操作 */
    function handleClean() {
      proxy.$modal.confirm("是否确认清空所有调度日志数据项?").then(function () {
        return cleanJobLog();
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("清空成功");
      }).catch(() => {});
    }
    /** 导出按钮操作 */
    function handleExport() {
      proxy.download("monitor/jobLog/export", {
        ...queryParams.value
      }, `job_log_${new Date().getTime()}.xlsx`);
    }
    (() => {
      const jobId = route.query.jobId;
      if (jobId !== undefined && jobId != 0) {
        getJob(jobId).then(response => {
          queryParams.value.jobName = response.data.jobName;
          queryParams.value.jobGroup = response.data.jobGroup;
          getList();
        });
      } else {
        getList();
      }
    })();
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      const _component_right_toolbar = __unplugin_components_0;
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_dict_tag = __unplugin_components_1;
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_2;
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_el_form, {
        model: _unref(queryParams),
        ref: "queryRef",
        inline: true,
        "label-width": "6.8rem"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "任务名称",
          prop: "jobName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).jobName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).jobName = $event),
            placeholder: "请输入任务名称",
            clearable: "",
            style: {
              "width": "24rem"
            },
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "任务组名",
          prop: "jobGroup"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(queryParams).jobGroup,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).jobGroup = $event),
            placeholder: "请选择任务组名",
            clearable: "",
            style: {
              "width": "24rem"
            }
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_job_group), dict => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: dict.value,
                label: dict.label,
                value: dict.value
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "执行状态",
          prop: "status"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(queryParams).status,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(queryParams).status = $event),
            placeholder: "请选择执行状态",
            clearable: "",
            style: {
              "width": "24rem"
            }
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_common_status), dict => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: dict.value,
                label: dict.label,
                value: dict.value
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "执行时间",
          style: {
            "width": "30.8rem"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            modelValue: _unref(dateRange),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(dateRange) ? dateRange.value = $event : null),
            "value-format": "YYYY-MM-DD",
            type: "daterange",
            "range-separator": "-",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            icon: "Search",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }), _createVNode(_component_el_button, {
            icon: "Refresh",
            onClick: resetQuery
          }, {
            default: _withCtx(() => [_createTextVNode("重置")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), [[_vShow, _unref(showSearch)]]), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "danger",
            plain: "",
            icon: "Delete",
            disabled: _unref(multiple),
            onClick: handleDelete
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['monitor:job:remove']]])]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "danger",
            plain: "",
            icon: "Delete",
            onClick: handleClean
          }, {
            default: _withCtx(() => [_createTextVNode("清空")]),
            _: 1
          })), [[_directive_hasPermi, ['monitor:job:remove']]])]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "warning",
            plain: "",
            icon: "Download",
            onClick: handleExport
          }, {
            default: _withCtx(() => [_createTextVNode("导出")]),
            _: 1
          })), [[_directive_hasPermi, ['monitor:job:export']]])]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "warning",
            plain: "",
            icon: "Close",
            onClick: handleClose
          }, {
            default: _withCtx(() => [_createTextVNode("关闭")]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_right_toolbar, {
          showSearch: _unref(showSearch),
          "onUpdate:showSearch": _cache[4] || (_cache[4] = $event => _isRef(showSearch) ? showSearch.value = $event : null),
          onQueryTable: getList
        }, null, 8, ["showSearch"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _unref(jobLogList),
        onSelectionChange: handleSelectionChange
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "selection",
          width: "55",
          align: "center"
        }), _createVNode(_component_el_table_column, {
          label: "日志编号",
          width: "80",
          align: "center",
          prop: "jobLogId"
        }), _createVNode(_component_el_table_column, {
          label: "任务名称",
          align: "center",
          prop: "jobName",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "任务组名",
          align: "center",
          prop: "jobGroup",
          "show-overflow-tooltip": true
        }, {
          default: _withCtx(scope => [_createVNode(_component_dict_tag, {
            options: _unref(sys_job_group),
            value: scope.row.jobGroup
          }, null, 8, ["options", "value"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "调用目标字符串",
          align: "center",
          prop: "invokeTarget",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "日志信息",
          align: "center",
          prop: "jobMessage",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "执行状态",
          align: "center",
          prop: "status"
        }, {
          default: _withCtx(scope => [_createVNode(_component_dict_tag, {
            options: _unref(sys_common_status),
            value: scope.row.status
          }, null, 8, ["options", "value"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "执行时间",
          align: "center",
          prop: "createTime",
          width: "180"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.parseTime(scope.row.createTime)), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作",
          align: "center",
          "class-name": "small-padding fixed-width"
        }, {
          default: _withCtx(scope => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            link: "",
            icon: "View",
            onClick: $event => handleView(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("详细")]),
            _: 2
          }, 1032, ["onClick"])), [[_directive_hasPermi, ['monitor:job:query']]])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[5] || (_cache[5] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[6] || (_cache[6] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        title: "调度日志详细",
        modelValue: _unref(open),
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _isRef(open) ? open.value = $event : null),
        width: "70rem",
        "close-on-click-modal": false,
        "append-to-body": "",
        draggable: ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
          onClick: _cache[7] || (_cache[7] = $event => open.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("关 闭")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          "label-width": "10rem"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "日志序号："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).jobLogId), 1)]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "任务名称："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).jobName), 1)]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "任务分组："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).jobGroup), 1)]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "执行时间："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).createTime), 1)]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "调用方法："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).invokeTarget), 1)]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "日志信息："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).jobMessage), 1)]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "执行状态："
              }, {
                default: _withCtx(() => [_unref(form).status == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, "正常")) : _unref(form).status == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_3, "失败")) : _createCommentVNode("", true)]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_unref(form).status == 1 ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: "异常信息："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).exceptionInfo), 1)]),
                _: 1
              })) : _createCommentVNode("", true)]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};