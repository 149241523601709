import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useRoute, useRouter } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const {
      params,
      query
    } = route;
    const {
      path
    } = params;
    router.replace({
      path: '/' + path,
      query
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div");
    };
  }
};