/* unplugin-vue-components disabled */import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-033a2a17"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-footer"
};
import { updateTaskModels } from '@/api/base/taskmanager';
export default {
  __name: 'model',
  props: {
    tableList: {
      type: Array,
      default: []
    }
  },
  emits: ["uploadTableList"],
  setup(__props, {
    expose: __expose,
    emit
  }) {
    const props = __props;
    const {
      proxy
    } = getCurrentInstance();
    const values = ref([]);
    const title = ref('模板数据设置'); // 表格数据
    const open = ref(false);
    function show() {
      for (const item in props.tableList) {
        if (props.tableList[item].isModel === "1") {
          values.value.push(parseInt(item));
        }
      }
      open.value = true;
    }
    function filterMethod(query, item) {
      return item.label.indexOf(query) > -1;
    }
    function dataChange() {
      const taskIds = [];
      if (values.value.length > 0) {
        props.tableList.map(item => {
          for (let i = 0; i < values.value.length; i++) {
            if (values.value[i] === item.key) {
              taskIds.push(item.taskId);
            }
          }
        });
      }
      const formData = new FormData();
      formData.append('taskIds', taskIds);
      updateTaskModels(formData).then(response => {
        close();
        proxy.$modal.msgSuccess("修改成功");
        emit("uploadTableList");
      });
    }
    function close() {
      open.value = false;
      values.value = [];
    }
    __expose({
      show
    });
    return (_ctx, _cache) => {
      const _component_el_transfer = _resolveComponent("el-transfer");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createBlock(_component_el_dialog, {
        title: _unref(title),
        modelValue: _unref(open),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(open) ? open.value = $event : null),
        onClose: _cache[4] || (_cache[4] = $event => close()),
        width: "80rem",
        "close-on-click-modal": false,
        "append-to-body": "",
        draggable: ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[1] || (_cache[1] = $event => dataChange())
        }, {
          default: _withCtx(() => [_createTextVNode("确定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[2] || (_cache[2] = $event => close())
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_transfer, {
          titles: ['原始数据', '模板数据'],
          filterable: "",
          "filter-method": filterMethod,
          "filter-placeholder": "请输入任务名称",
          modelValue: _unref(values),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(values) ? values.value = $event : null),
          data: __props.tableList
        }, null, 8, ["modelValue", "data"])]),
        _: 1
      }, 8, ["title", "modelValue"]);
    };
  }
};