import request from '@/utils/request'
import {parseStrEmpty} from "@/utils/satpro";

// 查询任务列表
export function listTask (query) {
  return request({
    url: '/alarm/task/list',
    method: 'get',
    params: query
  })
}
// 查询部门+任务下拉树结构
export function treeSelectTaskInDept(query) {
  return request({
    url: '/alarm/task/treeSelectTaskInDept',
    method: 'get',
    params: query
  })
}

// 查询任务详情
export function getTask(id) {
  return request({
    url: '/alarm/task/taskId/' + parseStrEmpty(id),
    method: 'get'
  })
}

// 查询邮件任务详情
export function getMessageInfo(id) {
  return request({
    url: '/alarm/task/message/' + parseStrEmpty(id),
    method: 'get'
  })
}

// 查询模型数据任务列表
export function getTaskList () {
  return request({
    url: '/alarm/task/getList',
    method: 'get'
  })
}
//更新模型数据
export function updateTaskModels (data) {
  return request({
    url: '/alarm/task/updateTaskModels',
    method: 'post',
    data: data
  })
}

// 查询无人机列表
export function getTreeselectDeviceList (query) {
  return request({
    url: '/device/getTreeselectDeviceList',
    method: 'get',
    params: query
  })
}

// 查询飞机列表，不带子设备
export function getTaskDeviceListNoChild () {
  return request({
    url: '/device/getTaskDeviceListNoChild',
    method: 'get'
  })
}


// 停止任务
export function stopTaskById (data) {
  return request({
    url: '/alarm/task/stopTaskById',
    method: 'post',
    data: data
  })
}

// 新增任务
export function addTask (data) {
  return request({
    url: '/alarm/task/addNewTask',
    method: 'post',
    data: data
  })
}

// 修改任务
export function updateTask (data) {
  return request({
    url: '/alarm/task/updateTask',
    method: 'put',
    data: data
  })
}

export function sendEmail (data) {
  return request({
    url: '/alarm/task/sendEmail',
    method: 'put',
    data: data
  })
}

// 开始执行任务
export function startTask (data) {
  return request({
    url: '/alarm/task/startTask',
    method: 'post',
    data: data
  })
}
// 录像下载
export function downloadMp4Start (data) {
  return request({
    url: '/videoDownload/downloadLiveRecordVideo',
    method: 'post',
    data: data
  })
}
// 录像下载状态查询
export function downloadStatusQuery (data) {
  return request({
    url: '/videoDownload/downloadStatusQuery',
    method: 'post',
    data: data
  })
}
// 录像下载状态查询
export function downloadDat (data) {
  return request({
    url: '/videoDownload/downloadDat',
    method: 'post',
    data: data
  })
}

// 删除任务
export function delTask (taskId) {
  return request({
    url: '/alarm/task/' + taskId,
    method: 'delete'
  })
}

// 查询当前设备是否正在执行任务
export function getTasking(query) {
  return request({
    url: '/alarm/task/getTasking',
    method: 'get',
    params: query
  })
}

// 限制执行任务数量为3
export function getTaskingCountEnough(query) {
  return request({
    url: '/alarm/task/getTaskingCountEnough',
    method: 'get',
    params: query
  })
}
